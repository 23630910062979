@use '../colours';

.mot_row {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding: 0 16px;
    align-items: center;
    gap: 16px;

    img{
        transition: .15s ease-in-out;
    }

    .result_icon {
        min-width: 18px;
        height: 18px;
    }

    .marker.blur{
        background-color: #fff !important;
    }

    p,h3 {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.25px;
    }

    .row_main {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        cursor: pointer;

        .row_main_left {
            display: flex;
            flex-flow: row nowrap;

            .count {
                border: 1px solid #171D1B;
                min-width: 33px;
                height: 33px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 100px;
                margin-right: 16px;
            }

            .date_cont {
                display: flex;
                flex-flow: column nowrap;
                gap: 6px;
                margin-right: 8px;

                .testno {
                    color: #49454F;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 16px;
                    letter-spacing: 0.4px;
                }
            }
        }


        .result_cont {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;
            // min-width: 62px;

            .marker{
                display: flex;
                gap: 8px;
                flex-flow: row nowrap;
                padding: 8px 16px;
                border-radius: 100px;
            }
    
            .marker.FAIL{
                background-color: colours.$fail_bg;
                p{
                    color: colours.$fail;
                }
            }

            .marker.PASS{
                background-color: colours.$secondary;
                p{
                    color: colours.$primary;
                }
            }

            p {
                text-transform: capitalize;
            }
        }
    }

    .row_details {
        width: calc(100% - 32px);
        background-color: #EFF1EF;
        border-radius: 12px;
        padding: 16px;
        align-self: flex-end;
        gap: 16px;
        display: flex;
        flex-flow: column nowrap;

        .notes_list.fail > h3{
            color: colours.$fail;
        }

        .notes_list.advi > h3{
            color: #E25600;
        }

        .notes_list {
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-start;
            padding: 0 8px;
            gap: 8px;

            h3{
                margin: 0;
            }

            ul{
                padding-inline-start: 25px;
                margin: 0;
            }

            .row {
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0.25px;
                color: #49454F;
            }
        }

        .mileage {
            display: flex;
            flex-flow: row norwap;
            justify-content: space-between;
            border-bottom: 1px solid colours.$sep;
            padding: 0 8px 16px 8px;
        }

        .no_border{
            border: none !important; 
            padding-bottom: 0 !important;
        }

        .expiry {
            display: flex;
            flex-flow: row norwap;
            justify-content: space-between;
            border-bottom: 1px solid colours.$sep;
            padding: 0 8px 16px 8px;
        }
    }

    .row_details.hide{
        display: none;
    }
}