#pass_rate_donut {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 0;

    .donut_label {
        font-size: calc(12px + 0.4vw);
        font-family: 'Nunito Sans', sans-serif;
        text-align: center;
    }

    svg {
        position: relative;
        transform: translateY(-25%) translateX(-25%);
        width: 200% !important;
        height: auto !important;
    }

}

#pass_rate_donut :nth-child(1) {
    // height: 120px;
    width: 140px;
}

#pass_rate_donut_mini {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 0;

    svg {
        position: relative;
        transform: translateY(-25%) translateX(-25%);
        width: 200% !important;
        height: auto !important;
    }

}

#pass_rate_donut_mini :nth-child(1) {
    // height: 36px;
    width: 36px;
}