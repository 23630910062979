@use '../colours';

.article {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    .article_opener {
        width: 100%;
        background-color: colours.$secondary;
        justify-content: center;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .opener_inner {
            display: flex;
            justify-content: space-between;

            .left {
                display: flex;
                flex-flow: column nowrap;
                text-align: left;
                align-items: flex-start;
                justify-content: center;

                .article_h1 {
                    margin: 0;
                    font-style: normal;
                    font-weight: 700;
                    font-family: 'Quattrocento', Georgia, serif;
                }

                .article_subtitle {
                    font-style: normal;
                    font-weight: 600;
                }
            }

            img:not(.plate>img) {
                border-radius: 24px;
                object-fit: cover;
            }

        }
    }

    .article_body {
        display: flex;
        justify-content: center;
        gap: 24px;

        .info {
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-start;

            .metadata {
                display: flex;
                flex-flow: column nowrap;
                border: 1px solid colours.$quaterniary;
                border-left: none;
                border-right: none;
                width: 100%;

                .meta_author {
                    color: colours.$primary;
                    font-weight: 400;
                    font-style: normal;
                    font-size: 22px;
                    line-height: 28px;
                }

                p:not(.meta_author) {
                    font-weight: 400;
                    color: colours.$quaterniary;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.25px;
                }
            }

            .socials {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                gap: 12px;
                width: 100%;

                img {
                    max-width: 40px;
                }

                a {
                    transition: .2s;
                }

                a:hover {
                    transform: scale(1.1);
                }
            }

        }

        .content {
            display: flex;
            flex-flow: column nowrap;

            .emphasise {
                font-size: 115%;
                margin-bottom: 15px;
            }

            .img_full_width {
                max-width: 95vw !important;
                min-height: 0 !important;
            }

            li {
                padding: 4px 0;
            }

            ul,
            ol {
                padding-left: 30px;
            }

            ol {
                margin-top: 0;
                margin-bottom: 32px;
            }

            ul:not(ul>ul) {
                margin-bottom: 64px;
            }

            a:not(.outbound) {
                text-decoration: none;
                color: colours.$off_black !important;
                pointer-events: none;
            }

            p:not(.loading_btn_cont>p, .blog_cta>p, .loading_btn_cont>p),
            li {
                color: colours.$off_black;
                font-weight: 400;
            }

            h2 {
                font-weight: 600;
                pointer-events: none;
                margin: 0;
                margin-bottom: 16px;
            }

            h3 {
                font-weight: 400;
                margin: 0;
                margin-bottom: 16px;
            }

            p:not(.img_caption>p, .single_caption>p, .blog_cta>p, .loading_btn_cont>p) {
                margin-bottom: 64px;
            }

            .img_full {
                width: 100%;
                align-self: center;
                margin-bottom: 64px;
                object-fit: cover;
            }

            .single_caption {
                margin-bottom: 64px;
                display: flex;
                flex-flow: column nowrap;
                align-items: center;
                justify-content: center;

                p {
                    font-size: 14px !important;
                    font-style: normal !important;
                    font-weight: 400 !important;
                    line-height: 20px !important;
                    letter-spacing: 0.25px !important;
                    color: colours.$quaterniary !important;
                    text-align: center !important;
                }

                img {
                    width: 100%;
                    margin-bottom: 18px;
                    border-radius: 8px;
                }

            }

            .double_caption {
                display: flex;
                gap: 48px;
                margin-bottom: 64px;

                .img_caption {
                    width: 50%;
                    display: flex;
                    flex-flow: column nowrap;
                    gap: 24px;

                    p {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0.25px;
                        color: colours.$quaterniary;
                        text-align: center;
                    }

                    img {
                        width: 100%;
                    }
                }

            }


        }

        .nav {
            min-width: 200px !important;
            position: relative;

            #scroll_point_cont :last-child.scroll_item {
                .inner_bar {
                    display: none;
                }

                .inner_circle {
                    transform: translate(0px, 0px) !important;
                }
            }

            #scroll_point_cont.float {
                position: fixed;
                top: 100px;
            }

            #scroll_point_cont {
                display: flex;
                flex-flow: column nowrap;
                justify-content: space-between;
                align-items: flex-end;
                text-align: right;
                border-radius: 3px;
                width: 200px;

                .scroll_item {
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    justify-content: flex-end;
                    gap: 12px;
                    width: 200px;
                    cursor: pointer;

                    p {
                        color: colours.$quaterniary;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 0.25px;
                        width: 70%;
                        opacity: 0.5;
                    }

                    .scroll_icon {
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: center;
                        align-items: center;
                        background-color: colours.$quaterniary;
                        width: 24px;
                        height: 24px;
                        border-radius: 100%;
                        border: 3px solid colours.$grey_bg_light;
                        aspect-ratio: 1;

                        .inner_circle {
                            width: 10px;
                            height: 10px;
                            transform: translate(2px, 0px);
                            border-radius: 100%;
                            aspect-ratio: 1;
                            background-color: colours.$grey_bg_light;
                        }

                        .inner_bar {
                            width: 3px;
                            background-color: colours.$grey_bg_dark;
                            height: 65px;
                            z-index: -1;
                            transform: translate(-6px, 45.5px);

                            .inner_bar_progress {
                                width: 3px;
                                background-color: colours.$primary;
                                height: 0;
                            }
                        }
                    }


                }

                .scroll_item.visited {
                    p {
                        opacity: 1;
                    }

                    .scroll_icon {
                        background-color: colours.$primary;

                        .inner_circle {
                            background-color: colours.$grey_bg_dark;
                        }
                    }
                }

                .scroll_item.active {
                    p {
                        opacity: 1;
                    }

                    .scroll_icon {
                        background-color: colours.$primary;
                        border: 3px solid #99F6E0;

                        .inner_circle {
                            background-color: colours.$grey_bg_dark;
                        }


                    }
                }
            }
        }
    }
}


// RED - 1128px
@media only screen and (min-width: 1240px) {
    .article {
        .article_opener {
            .opener_inner {
                margin-top: 80px;
                padding: 128px 0;
                width: 1128px;
                gap: 86px;

                img:not(.plate>img) {
                    height: 429px;
                    width: 516px;
                }

                .left {
                    gap: 56px;
                    max-width: 516px;

                    .article_h1 {
                        font-size: 57px;
                        line-height: 64px;
                        letter-spacing: -0.25px;
                    }

                    .article_subtitle {
                        font-size: 32px;
                        line-height: 40px;
                    }
                }
            }
        }

        .article_body {
            width: 1128px;
            margin: 128px 0;

            .info {
                width: 168px;

                .metadata {
                    padding: 32px 0;
                    gap: 24px;
                    margin-bottom: 32px;
                }
            }

            .content {
                min-width: 733px;

                h2 {
                    font-size: 32px;
                    line-height: 40px;
                }

                h3 {
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.15px;
                }

                h3.h3_highlight {
                    font-size: 20px;
                    line-height: 26px;
                    letter-spacing: 0.2px;
                    font-weight: 600;
                    margin-bottom: 24px;
                }

                p:not(.loading_btn_cont>p, .blog_cta>p, .loading_btn_cont>p),
                li {
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.15px;
                }
            }

        }
    }
}

// YELLOW - 100% - 64px
@media only screen and (min-width: 905px) and (max-width: 1239px) {
    .article {
        .article_opener {
            .opener_inner {
                margin-top: 80px;
                padding: 96px 0;
                width: calc(100% - 64px);
                gap: 48px;

                .left {
                    gap: 56px;
                    min-width: 408px;
                    max-width: 540px;

                    .article_h1 {
                        font-size: 45px;
                        line-height: 52px;
                    }

                    .article_subtitle {
                        font-size: 28px;
                        line-height: 36px;
                    }
                }

                img:not(.plate>img) {
                    height: 429px;
                    width: 408px;
                }
            }
        }

        .article_body {
            width: calc(100% - 64px);
            margin: 96px 0;

            .info {
                width: 149px;

                .metadata {
                    padding: 32px 0;
                    gap: 24px;
                    margin-bottom: 32px;
                }
            }

            .content {
                min-width: 480px;

                h2 {
                    font-size: 28px;
                    line-height: 36px;
                }

                h3 {
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.15px;
                }

                p:not(.loading_btn_cont>p, .blog_cta>p, .loading_btn_cont>p),
                li {
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.15px;
                }
            }
        }
    }
}

// GREEN - 100% - 32px
@media only screen and (min-width: 600px) and (max-width: 904px) {
    .article {
        .article_opener {
            .opener_inner {
                width: calc(100% - 32px);
                margin-top: 80px;
                padding: 64px 0;
                gap: 64px;
                flex-flow: column nowrap;

                .left {
                    gap: 56px;

                    .article_h1 {
                        font-size: 36px;
                        line-height: 44px;
                    }

                    .article_subtitle {
                        font-size: 24px;
                        line-height: 32px;
                    }
                }

                img:not(.plate>img) {
                    max-height: 388px;
                }
            }
        }

        .article_body {
            width: calc(100% - 32px);
            margin: 64px 0;

            .info {
                width: 149px;

                .metadata {
                    padding: 32px 0;
                    gap: 24px;
                    margin-bottom: 32px;
                }
            }

            .content {
                min-width: 363px;

                h2 {
                    font-size: 24px;
                    line-height: 32px;
                }

                h3 {
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.1px;
                }

                p:not(.loading_btn_cont>p, .blog_cta>p, .loading_btn_cont>p),
                li {
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.1px;
                }

                .img_full {
                    min-height: 420px;
                }

            }

            .nav {
                display: none;
            }
        }
    }
}

// BLUE - 100% - 32px
@media only screen and (max-width: 599px) {
    .article {

        .article_opener {
            .opener_inner {
                width: calc(100% - 32px);
                margin-top: 88px;
                padding: 48px 0;
                gap: 32px;
                flex-flow: column nowrap;

                .left {
                    gap: 40px;

                    .article_h1 {
                        font-size: 36px;
                        line-height: 44px;
                    }

                    .article_subtitle {
                        font-size: 24px;
                        line-height: 32px;
                    }
                }

                img:not(.plate>img) {
                    max-height: 238px;
                }
            }
        }

        .article_body {
            width: calc(100% - 32px);
            margin: 64px 0 100px 0;
            flex-flow: column nowrap;

            .info {
                width: 100%;
                gap: 32px;
                flex-flow: row nowrap;
                margin-bottom: 12px;

                .metadata {
                    gap: 12px;
                    border: none !important;
                }

                .socials {
                    justify-content: flex-end;
                }
            }

            .content {
                min-width: 100%;

                h2 {
                    font-size: 24px;
                    line-height: 32px;
                }

                h3 {
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.1px;
                }

                h3.h3_highlight {
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: 0.15px;
                    font-weight: 600 !important;
                }

                p:not(.loading_btn_cont>p, .blog_cta>p, .loading_btn_cont>p),
                li {
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.1px;
                }

                .img_full {
                    min-height: 420px;
                }
            }

            .nav {
                display: none;
            }
        }
    }
}