@use '../../colours';

#confirm_and_pay_panel {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin-top: calc(40px + 16px + 1px + 23px);

    h1 {
        font-family: 'Quattrocento', 'Georgia', serif;
        font-style: normal;
        font-weight: 700;
        text-align: left;
        width: 100%;
    }

    #content_cont {
        display: flex;

        #plate_check {
            display: flex;
            flex-flow: column nowrap;

            #btn_cont {
                display: flex;
                flex-flow: row nowrap;
                gap: 16px;

                * {
                    font-family: 'Nunito Sans', sans-serif;
                }
            }

            .info_box {
                display: flex;
                flex-flow: row nowrap;
                background-color: colours.$quaterniary;
                border-radius: 12px;
                align-items: center;
                justify-content: space-between;

                .info_left_cont {
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;

                    .info_image {
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: scale-down;
                        }
                    }

                    .info_vehicle {
                        display: flex;
                        flex-flow: column nowrap;
                        justify-content: space-evenly;
                        gap: 5px;

                        p {
                            font-style: normal;
                            color: white;
                        }

                        .make {
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 24px;
                            letter-spacing: 0.15px;
                            text-transform: capitalize;
                        }

                        .year {
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 20px;
                            letter-spacing: 0.25px;
                        }
                    }
                }

                .info_plate {
                    font-family: 'Nunito Sans', sans-serif;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 28px;
                    background: #FEC84B;
                    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
                    align-items: center;
                    gap: 4px;
                    border-radius: 4px;
                    text-align: center;
                    text-transform: uppercase;
                }

                .info_right {
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;

                    .info_menu {
                        display: flex;
                        flex-flow: row nowrap;
                        padding: 0 8px 0 12px;
                        cursor: pointer;

                        img {
                            transition: .15s ease-in-out;
                        }

                        img:hover {
                            opacity: 0.5;
                        }

                        .menu_items.hide {
                            display: none;
                        }

                        .menu_items {
                            display: flex;
                            flex-flow: column nowrap;
                            background-color: colours.$neutral;
                            position: absolute;
                            margin-left: 25px;
                            width: 220px;
                            box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
                            border-radius: 4px;
                            padding: 8px 0;
                            transform: translate(-215px, 35px);

                            a {
                                text-decoration: none;
                            }


                            .item:hover {
                                background-color: #DDE4E1;
                            }

                            .item {
                                padding: 8px 12px;
                                border-radius: 4px;
                                height: 40px;
                                display: flex;
                                flex-flow: column nowrap;
                                justify-content: center;


                                p {
                                    font-size: 16px;
                                    font-weight: 600;
                                    line-height: 24px;
                                    letter-spacing: 0.5px;
                                }
                            }
                        }
                    }
                }

            }

            .info_box.error {
                background-color: colours.$fail_bg;
                border: 1px solid colours.$fail;

                p {
                    color: colours.$off_black !important;
                }
            }
        }


        #payment_info {
            display: flex;
            flex-flow: column nowrap;

            .alert {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                border-radius: 12px;
                padding: 1.5em 1em;
                gap: 1em;
                box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 8px 16px -4px rgba(16, 24, 40, 0.08);
                border: 1px solid colours.$warn_border_light;
                background-color: colours.$warn_bg_light;

                img {
                    width: 18px;
                    min-width: 18px;
                    max-width: 18px;
                    align-self: flex-start;
                }

                #alert_text {
                    display: flex;
                    flex-flow: column nowrap;
                    gap: 0.5em;

                    h3 {
                        margin: 0;
                        font-weight: 600;
                        color: colours.$warn;
                        font-size: 16px;
                    }

                    p {
                        color: colours.$warn;
                        font-size: 14px;
                    }
                }
            }


            #package_info {
                user-select: none;
                border: 1px solid colours.$grey_border_light;
                padding: 2em 2em 1.8em 2em;
                box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 8px 16px -4px rgba(16, 24, 40, 0.08);
                border-radius: 14px;
                display: flex;
                flex-flow: column nowrap;
                justify-content: space-between;
                gap: 0.5em;

                #discount {
                    p {
                        color: colours.$primary;
                    }
                }

                .package_row :first-child {
                    font-weight: 500;
                }

                #total {
                    font-weight: 500;
                    font-size: 18px;
                }

                .package_row {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: space-between;
                }

                .sep {
                    border-bottom: 1px dashed colours.$grey_border_light;
                    margin: 0.2em 0;
                    margin-top: 1.2em;
                }
            }

            #stripe_cont {
                display: flex;
                flex-flow: column nowrap;
                align-items: center;
            }
        }
    }
}

// RED
@media only screen and (min-width: 1240px) {
    #confirm_and_pay_panel {
        width: 1128px;
        margin-bottom: 128px;

        h1 {
            margin: 128px 0 56px 0;
            font-size: 57px;
            line-height: 64px;
            letter-spacing: -0.25px;
        }

        #content_cont {
            width: 100%;
            gap: 24px;
            flex-flow: row nowrap;

            #plate_check {
                width: 50%;
                gap: 24px;

                .info_box {
                    padding: 18px 8px 18px 16px;

                    .info_left {
                        .info_image {
                            width: 75px;
                            height: 60px;
                            margin-right: 16px;
                        }
                    }

                    .info_plate {
                        font-size: 22px;
                        padding: 4px 16px;
                        width: 110px;
                    }
                }

            }

            #payment_info {
                width: 50%;
                gap: 24px;
            }
        }
    }
}

// YELLOW
@media only screen and (min-width: 905px) and (max-width: 1239px) {
    #confirm_and_pay_panel {
        width: calc(100% - 64px);
        margin-bottom: 96px;

        h1 {
            margin: 96px 0 56px 0;
            font-size: 45px;
            line-height: 52px;
        }

        #content_cont {
            width: 100%;
            gap: 24px;
            flex-flow: row nowrap;

            #plate_check {
                width: 50%;
                gap: 24px;

                .info_box {
                    padding: 18px 8px 18px 16px;

                    .info_left {
                        .info_image {
                            width: 75px;
                            height: 60px;
                            margin-right: 16px;
                        }
                    }

                    .info_plate {
                        font-size: 22px;
                        padding: 4px 16px;
                        width: 110px;
                    }
                }
            }

            #payment_info {
                width: 50%;
                gap: 24px;
            }
        }
    }
}

@media only screen and (min-width: 1240px) and (max-width: 1439px) {
    #confirm_and_pay_panel {
        h1 {
            margin: 70px 0 56px 0 !important;
        }
    }
}

// GREEN
@media only screen and (min-width: 600px) and (max-width: 904px) {
    #confirm_and_pay_panel {
        width: calc(100% - 32px);
        margin-bottom: 64px;

        h1 {
            margin: 96px 0 56px 0;
            font-size: 36px;
            line-height: 44px;
        }

        #content_cont {
            width: 100%;
            gap: 32px;
            flex-flow: column nowrap;

            #plate_check {
                width: 100%;
                gap: 16px;

                #btn_cont {
                    justify-content: center;
                }

                #review {
                    margin: 8px 0;
                }

                .info_box {
                    padding: 18px 8px 18px 16px;

                    .info_left {
                        .info_image {
                            width: 75px;
                            height: 60px;
                            margin-right: 16px;
                        }
                    }

                    .info_plate {
                        font-size: 22px;
                        padding: 4px 16px;
                        width: 110px;
                    }

                    .info_right {
                        .info_menu {
                            z-index: 1;

                            .menu_items {
                                transform: translate(-190px, 30px);
                            }
                        }
                    }
                }
            }

            #payment_info {
                width: 100%;
                gap: 16px;
            }
        }
    }
}

// BLUE
@media only screen and (max-width: 599px) {
    #confirm_and_pay_panel {
        width: calc(100% - 32px);
        margin-bottom: 48px;

        h1 {
            margin: 48px 0 40px 0;
            font-size: 36px;
            line-height: 44px;
        }

        #content_cont {
            width: 100%;
            gap: 32px;
            flex-flow: column nowrap;

            #plate_check {
                width: 100%;
                gap: 16px;

                #btn_cont {
                    justify-content: center;
                }

                #review {
                    margin: 0 0 8px 0;
                    order: -1;
                }

                .info_box {
                    padding: 24px 16px 24px 24px;

                    .info_plate {
                        font-size: 16px;
                        padding: 2px 8px;
                        width: 110px;
                    }

                    .info_left {
                        display: flex;
                        flex-flow: column nowrap;
                        gap: 16px;

                        .info_image {
                            width: 75px;
                            height: 60px;
                            margin-right: 16px;
                        }
                    }

                    .info_right {
                        height: 100%;
                        align-items: flex-start;

                        .info_menu {
                            z-index: 1;
                        }
                    }
                }
            }

            #payment_info {
                width: 100%;
                gap: 16px;
            }
        }
    }
}