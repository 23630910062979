@use 'colours';

* {
    -webkit-tap-highlight-color: transparent;
}

:root {
    --md-sys-color-primary: #006B5F;
    --md-sys-color-on-primary: white;
    --md-filled-button-container-leading-space: 1px;
    --md-filled-tonal-button-container-color: #CBE6FF;
    --md-filled-tonal-button-label-text-color: #001E30;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

::-webkit-scrollbar-thumb {
    // border-radius: 100px;
    background-color: rgba(0, 0, 0, .1);
}

#tp_link {
    border: 1px solid colours.$quaterniary;
    width: fit-content;
    padding: 4px 4px 4px 16px;
    border-radius: 4px;
    display: flex;
    flex-flow: row nowrap;
    gap: 2px;

    a {
        color: colours.$off_black;
        display: flex;
        flex-flow: row nowrap;
        text-decoration: none;
        align-items: center;
        gap: 8px;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.15px;
        text-align: center;
    }
}

body {
    margin: 0;
    font-family: 'Nunito Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: auto;
    overflow-x: hidden !important;
}

h1 {
    // font-family: 'Quattrocento', 'Georgia', serif;
    font-weight: 700;
    color: colours.$off_black;
}

h2,
h3,
h4,
h5 {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 700;
    color: colours.$off_black;
}

html {
    overflow-x: hidden;
}

#root {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    overflow-x: hidden !important;
    overflow-y: hidden;

}

p {
    margin: 0;
    color: colours.$off_black;
}

.bold_text {
    font-weight: 700;
}

img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-select: none;
}

#leadinModal-18900867 {
    display: none;
}