@use '../colours';

#main_header {
    z-index: 998;
    position: fixed;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);

    #banner {
        background-color: colours.$primary;
        height: 20px;
        padding: 2px 0;
        width: 100%;
        text-align: center;

        p,
        a {
            transition: .3s ease-in-out;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0.1px;
            color: white;
            padding-top: 1px;
            user-select: none;
        }

        #banner_1 {
            transform: translateY(-22px);
        }

        #banner_2 {
            transform: translateY(-42px);
        }

        #banner_1.show {
            transform: none !important;
        }

        #banner_2.show {
            transform: translateY(-22px);
        }

    }

    #header_content {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;

        img {
            width: 96px;
            cursor: pointer;
        }

        .hamburger-menu {
            display: flex;
            margin-right: 0;
            padding: 8px;

            .menu-items {
                width: auto;
                left: auto;
                border: 1px solid colours.$grey_border_dark;
                margin-top: calc(40px + 16px);

                .ham_btn {
                    padding: 1em 2em;
                }
            }

            .menu-items :last-child {
                border-bottom: none;
            }
        }

        #header_right {
            display: flex;
            flex-flow: row nowrap;
            gap: 8px;
        }

        #report_mob_head_plate {
            background-color: colours.$plate;
            height: min-content;
            border-radius: 6px;
            padding: 2px 12px;

            p {
                font-size: 16px;
                font-style: normal;
                font-weight: 800;
                line-height: 24px;
                letter-spacing: 0.15px;
            }
        }
    }
}

@media only screen and (min-width: 1920px) {
    #header_content:not(#report_header>#header_content) {
        width: 1128px;
        align-self: center;
        padding: 8px 0;
        height: 40px;

        .hamburger-menu {
            .menu-items {
                right: calc((100% - 1128px) / 2);
            }
        }
    }
}

@media only screen and (min-width: 1612px) and (max-width: 1919px){
    #header_content:not(#report_header>#header_content) {
        width: 1128px;
        margin-left: 404px;

        align-self: flex-start;
        padding: 8px 0;
        height: 40px;

        .hamburger-menu {
            .menu-items {
                right: calc((100% - 1128px) / 2);
            }
        }
    }
}

@media only screen and (min-width: 1240px) and (max-width: 1611px) {
    #header_content:not(#report_header>#header_content) {
        width: calc(100% - 500px) !important;
        padding: 8px 0;
        // border: 2px solid red;
        height: 40px;

        .hamburger-menu {
            .menu-items {
                right: calc((100% - 1128px) / 2);
            }
        }
    }
}

@media only screen and (min-width: 905px) and (max-width: 1239px) {
    #header_content:not(#report_header>#header_content) {
        width: calc(100% - 110px) !important;

        padding: 8px 20px 8px 10px;
        // border: 2px solid yellow;
        height: 40px;

        .hamburger-menu {
            .menu-items {
                right: 0;
            }
        }
    }
}

@media only screen and (min-width: 600px) and (max-width: 904px) {
    #header_content:not(#report_header>#header_content) {
        width: calc(100% - 60px);
        padding: 8px 20px 8px 10px;
        // border: 2px solid green;
        height: 40px;

        .hamburger-menu {
            .menu-items {
                right: 0;
            }
        }
    }
}

@media only screen and (max-width: 599px) {
    #header_content:not(#report_header>#header_content) {
        width: calc(100% - 32px);
        padding: 8px 16px 8px 16px;
        // border: 2px solid blue;
        height: 40px;

        .hamburger-menu {

            .menu-items {
                width: 100vw !important;
                right: 0 !important;
            }
        }
    }

    #banner {

        p,
        a {
            font-size: 13px !important;
            white-space: nowrap;
        }
    }
}