@use '../../colours';

.multi_donut{
    user-select: none;
    pointer-events: none;

    // span:has(#label){
    //     left: 111px !important;
    // }
    
    #label{
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
        background-color: #E3EAE7;
        border-radius: 16px;
        padding: 12px 16px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 8px;

        p {
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.1px;
        }
    
    }

    #label>p:not(.blur>p){
        color: colours.$primary;

    }

    .blur {
        p{
            color: transparent !important;
            text-shadow: 0 0 6px colours.$primary !important;
        }
    
    }
}