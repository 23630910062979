@use '../colours';

.hamburger-menu {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 18px;
    height: 12px;
    cursor: pointer;
    align-self: center;
    margin-right: 1rem;

    span {
        width: 100%;
        height: 2px;
        transition: transform 0.3s ease-in-out;
        border-radius: 3px;
    }

    &.open {
        span:first-child {
            transform: translateY(5px) rotate(45deg);
        }

        span:nth-child(2) {
            opacity: 0;
        }

        span:nth-child(3) {
            transform: translateY(-5px) rotate(-45deg);
        }

        .menu-items {
            opacity: 0;
            animation: slideIn 0.5s forwards;
        }
    }

    @keyframes slideIn {
        from {
            opacity: 0;
            transform: translateY(-15px);
        }

        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    .menu-items {
        display: flex;
        flex-flow: column nowrap;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        background-color: white;
        z-index: 50;


        .ham_btn {
            border: none;
            background-color: white;
            font-size: 17px;
            font-weight: bold;
            padding: 1em 0;
            border-bottom: 1px solid colours.$grey_border_dark;
            transition: .2s;
            border-radius: 2px;
            user-select: none;
        }

        .ham_btn:hover {
            cursor: pointer;
            background-color: colours.$grey_bg_med;
            color: colours.$primary;
        }

        .ham_inactive {
            color: colours.$grey_text_light;
        }

        .ham_active {
            color: colours.$primary;
        }
    }
}