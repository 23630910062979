@use '../../colours';

.review_card {
    border-radius: 28px;
    background-color: #E3EAE7;
    display: flex;
    flex-flow: column nowrap;
    gap: 16px;
    padding: 24px;
    align-items: center;

    q {
        font-size: 16px;
        font-style: italic;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: #3F4946;
        margin-bottom: 8px;
        text-align: center;
    }

    p {
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
    }

    .review_footer {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        gap: 16px;

        .avatar {
            max-height: 50px;
        }

        a>p {
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.1px;
            text-decoration: none;
        }
    
        a{
            text-decoration: none;
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            gap: 4px;
        }
    }
}

// BLUE - 100% - 32px
@media only screen and (min-width: 600px) {
    .review_card:not(.not_report){
        display: none;
    }
}