@use '../colours';

.main_faq {
    display: flex;
    flex-flow: column nowrap;

    .question {
        border-radius: 4px;
        border-bottom: 1px solid colours.$sep;
        background: colours.$secondary;
        padding: 8px 16px 8px 16px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        gap: 16px;
        cursor: pointer;
        transition: .15s ease-in-out;

        p {
            font-style: normal;
            font-weight: 600;
            user-select: none;
        }

        img {
            transition: .3s ease-in-out;
        }

        img.rotate {
            transform: rotate(-180deg);
        }
    }

    .question:hover {
        background: #e6f1ec;
    }

    .answer {
        background-color: colours.$neutral;
        height: 0;
        border-radius: 0 0 4px 4px;

        p,
        a:not(.normal_link),
        li {
            display: none;
            font-style: normal;
            font-weight: 600;
            white-space: pre-line;
        }
    }

    .answer.show {
        height: max-content;
        padding: 32px 16px;

        p,
        a:not(.normal_link) {
            display: block;
        }

        li {
            display: list-item;
        }
    }
}



// RED
@media only screen and (min-width: 1240px) {
    .main_faq {
        .question, .answer {
            p {
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.5px;
            }
        }
    }
}

// YELLOW
@media only screen and (min-width: 905px) and (max-width: 1239px) {
    .main_faq {
        .question, .answer {
            p {
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.5px;
            }
        }
    }
}

// GREEN
@media only screen and (min-width: 600px) and (max-width: 904px) {
    .main_faq {
        .question, .answer {
            p {
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.25px;
            }
        }
    }
}

// BLUE
@media only screen and (max-width: 599px) {
    .main_faq {

        .question>p,
        .answer>p,
        .answer>ul>li {
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0.25px;
        }
    }
}