@use '../colours';

#main_pricing {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    .marker {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        height: 40px !important;
        width: 40px !important;
        aspect-ratio: 1;
        border-radius: 100%;
        background-color: colours.$tertiary;

        p {
            font-family: Nunito Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0.15px;
        }
    }

    *{
        transition: .3s ease-in-out; 
    }

    #pricing {
        margin-top: calc(40px + 16px + 1px + 23px);

        h1 {
            font-family: 'Quattrocento', 'Georgia', serif;
            font-style: normal;
            font-weight: 700;
        }

        #pricing_cards {
            display: flex;

            .card {
                padding: 11px 16px 16px 16px;
                border-radius: 12px;
                background-color: colours.$secondary;
                border: 1px solid colours.$sep;
                display: flex;
                flex-flow: column nowrap;
                align-items: center;
                height: fit-content;

                .quantity {
                    display: flex;
                    flex-flow: row nowrap;
                    gap: 16px;
                    align-items: center;
                    align-self: baseline;

                    h2 {
                        font-family: Nunito Sans;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 24px;
                        letter-spacing: 0.15px;
                    }


                }

                .price {
                    font-family: 'Quattrocento', sans-serif;
                    font-style: normal;
                    font-weight: 700;
                }

                .discount {
                    display: flex;
                    flex-flow: row nowrap;
                    align-self: center;

                    .prev {
                        font-style: normal;
                        text-decoration: line-through;
                        color: #3F4946;
                    }

                    .perc {
                        color: colours.$fail;
                        font-style: normal;
                    }
                }

                .inputs.hide {
                    height: 0 !important;
                    opacity: 0 !important;
                    margin: 0 !important;

                    input{
                        height: 0 !important;
                    }
                }

                #inputs_1{
                    height: 34px;
                }

                #inputs_2{
                    height: 76px;
                }

                #inputs_3{
                    height: 118px;
                }

                .inputs {
                    display: flex;
                    flex-flow: column nowrap;
                    gap: 8px;

                    input {
                        font-size: 1.2rem;
                        font-weight: 700;
                        text-transform: uppercase;
                        background-color: colours.$plate;
                        border: none;
                        padding: 6px 16px;
                        align-items: center;
                        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
                        text-align: center;
                        border-radius: 4px;
                        height: 22px;
                    }

                    input::placeholder {
                        color: #4D635E;
                        font-family: Nunito Sans;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 24px;
                        letter-spacing: 0.15px;
                        text-transform: none;
                    }


                    input:focus {
                        outline: none;
                    }
                }

                #btn_1,
                #btn_2,
                #btn_3 {
                    font-family: 'Nunito Sans', sans-serif;
                }
            }
        }

    }

    #why_choose_us {
        width: 100%;
        background-color: colours.$secondary;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;

        #why_inner {
            h2 {
                font-family: 'Quattrocento', 'Georgia', serif;
                font-style: normal;
                font-weight: 700;
            }

            #why_content {
                display: flex;

                #list {
                    display: flex;
                    flex-flow: column nowrap;

                    .list_item {
                        display: flex;
                        flex-flow: row nowrap;
                        align-items: center;
                        justify-content: space-between;

                        .marker {
                            margin-right: 16px;
                        }

                        p:not(.marker>p) {
                            flex-grow: 1;
                            text-align: left;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 24px;
                            letter-spacing: 0.5px;
                        }
                    }
                }
            }
        }

    }
}


// RED
@media only screen and (min-width: 1240px) {
    #main_pricing {
        #pricing {
            width: 1128px;

            h1 {
                margin: 128px 0 56px 0;
                font-size: 57px;
                line-height: 64px;
                letter-spacing: -0.25px;
            }

            #pricing_cards {
                gap: 24px;
                margin-bottom: 128px;
                flex-flow: row nowrap;

                .card {
                    width: 328px;

                    .inputs {
                        margin-bottom: 39px;
                        max-width: 160px;
                    }

                    img {
                        margin: 27px 0 35px 0;
                    }

                    .price {
                        font-size: 57px;
                        line-height: 64px;
                        letter-spacing: -0.25px;
                    }

                    .discount {
                        height: 28px;
                        margin-bottom: 39px;
                        gap: 0.5em;

                        .prev {
                            font-size: 22px;
                            font-weight: 400;
                            line-height: 28px;
                        }

                        .perc {
                            font-size: 22px;
                            font-weight: 700;
                            line-height: 28px;
                        }
                    }
                }
            }
        }

        #why_choose_us {
            padding: 128px 0;

            #why_inner {
                width: 1128px;

                h2 {
                    font-size: 57px;
                    line-height: 64px;
                    letter-spacing: -0.25px;
                    margin: 0 0 56px 0;
                }

                #why_content {
                    flex-flow: row nowrap;
                    gap: 96px;

                    #why_text {
                        width: 516px;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 24px;
                        letter-spacing: 0.5px;
                    }

                    #list {
                        width: 516px;

                        .list_item {
                            padding: 4px 24px 4px 16px;
                        }
                    }
                }
            }
        }
    }
}

// YELLOW
@media only screen and (min-width: 905px) and (max-width: 1239px) {
    #main_pricing {
        #pricing {
            width: calc(100% - 64px);

            h1 {
                margin: 96px 0 56px 0;
                font-size: 45px;
                line-height: 52px;
            }

            #pricing_cards {
                gap: 24px;
                margin-bottom: 96px;
                flex-flow: row nowrap;

                .card {
                    flex-grow: 1;

                    .inputs {
                        margin-bottom: 32px;
                        max-width: 160px;
                    }

                    img {
                        margin: 33px 0;
                    }

                    .price {
                        font-size: 45px;
                        line-height: 52px;
                    }

                    .discount {
                        height: 28px;
                        margin-bottom: 32px;
                        gap: 0.5em;

                        .prev {
                            font-size: 22px;
                            font-weight: 400;
                            line-height: 28px;
                        }

                        .perc {
                            font-size: 22px;
                            font-weight: 700;
                            line-height: 28px;
                        }
                    }
                }
            }
        }

        #why_choose_us {
            padding: 96px 0;

            #why_inner {
                width: calc(100% - 40px);

                h2 {
                    font-size: 45px;
                    line-height: 52px;
                    margin: 0 0 56px 0;

                }

                #why_content {
                    flex-flow: row nowrap;
                    justify-content: space-between;
                    gap: 15px;

                    #why_text {
                        width: 50%;
                        max-width: 500px;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 24px;
                        letter-spacing: 0.5px;
                    }

                    #list {
                        width: 50%;
                        max-width: 500px;

                        .list_item {
                            padding: 4px 24px 4px 16px;
                        }
                    }
                }
            }

        }
    }
}

@media only screen and (min-width: 1240px) and (max-width: 1439px) {
    #pricing {
        h1 {
            margin: 70px 0 56px 0 !important;
        }
    }
}

// GREEN
@media only screen and (min-width: 600px) and (max-width: 904px) {
    #main_pricing {
        #pricing {
            width: calc(100% - 32px);

            h1 {
                margin: 96px 0 56px 0;
                font-size: 36px;
                line-height: 44px;
            }

            #pricing_cards {
                gap: 16px;
                margin-bottom: 96px;
                flex-flow: row nowrap;

                .card {
                    flex-grow: 1;

                    .inputs {
                        margin-bottom: 32px;
                        max-width: 145px;
                    }

                    img {
                        margin: 14px 0;
                        max-width: 24vw;
                    }

                    .price {
                        font-size: 36px;
                        line-height: 44px;
                    }

                    .discount {
                        height: 24px;
                        margin-bottom: 32px;
                        gap: 0.5em;

                        .prev {
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 24px;
                            letter-spacing: 0.15px;
                        }

                        .perc {
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 24px;
                            letter-spacing: 0.15px;
                        }
                    }
                }
            }
        }

        #why_choose_us {
            padding: 96px 0 120px 0;

            #why_inner {
                width: calc(100% - 60px);

                h2 {
                    font-size: 36px;
                    line-height: 44px;
                    margin: 0 0 56px 0;

                }

                #why_content {
                    flex-flow: column nowrap;
                    align-items: center;
                    gap: 40px;

                    #why_text {
                        width: 100%;

                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 0.5px;
                    }

                    #list {
                        width: 100%;
                        max-width: 520px;

                        .list_item {
                            padding: 4px 24px 4px 16px;
                        }
                    }
                }
            }

        }
    }
}

// BLUE
@media only screen and (max-width: 599px) {
    #main_pricing {
        #pricing {
            width: calc(100% - 32px);

            h1 {
                margin: 48px 0 40px 0;
                font-size: 36px;
                line-height: 44px;
            }

            #pricing_cards {
                gap: 16px;
                margin-bottom: 80px;
                flex-flow: column nowrap;

                .card {
                    flex-grow: 1;

                    .inputs {
                        margin-bottom: 16px;
                        max-width: 170px;
                    }

                    img {
                        display: none;
                    }

                    .price {
                        font-size: 36px;
                        line-height: 44px;
                    }

                    .discount {
                        height: 24px;
                        margin-bottom: 16px;
                        gap: 0.5em;

                        .prev {
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 24px;
                            letter-spacing: 0.15px;
                        }

                        .perc {
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 24px;
                            letter-spacing: 0.15px;
                        }
                    }
                }

            }


        }

        #why_choose_us {
            padding: 80px 0 135px 0;

            #why_inner {
                width: calc(100% - 32px);

                h2 {
                    font-size: 36px;
                    line-height: 44px;
                    margin: 0 0 40px 0;
                }

                #why_content {
                    flex-flow: column nowrap;
                    gap: 40px;

                    #why_text {
                        width: 100%;

                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 24px;
                        letter-spacing: 0.5px;
                    }

                    #list {
                        width: 100%;

                        .list_item {
                            padding: 8px 0 8px 0;
                        }
                    }
                }
            }

        }
    }
}