@use '../colours';

.check_item_status {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding: 0 8px;
    gap: 16px;

    p:not(.default_key, .default_val, .tt_text_p) {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.25px;
    }

    img{
        transition: .15s ease-in-out;
    }

    .item_header {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0 8px;
        cursor: pointer;

        .marker {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: 8px;
            padding: 8px 16px;
            border-radius: 100px;

            img {
                max-height: 18px;
                max-width: 18px;
                transform: translateY(-1px);
            }
        }
    }

    .item_details {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding: 8px 16px;
        border-radius: 12px;
        align-items: center;
        height: 40px;
        gap: 8px;
        text-align: right;

        .key {
            color: #49454F;
        }
    }

    .hide {
        display: none;
    }

    .item_right {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 12px;
        cursor: pointer;
    }

    .title_cont{
        display: flex;
        flex-flow: row nowrap;
        gap: 4px;
        align-items: center;
        cursor: default;
        padding: 8px 16px 8px 0;
    }

}

.check_item_status.fail {
    .marker {
        background-color: colours.$fail_bg;
    }

    .marker>p,
    .item_val {
        color: colours.$fail;
    }

    .item_details {
        background-color: colours.$fail_bg;
    }
}

.check_item_status.pass {
    .marker {
        background-color: colours.$secondary;
    }

    .marker>p,
    .item_val {
        color: colours.$primary;
    }

    .item_details {
        background-color: colours.$secondary;
    }
}

.check_item_status.warn {
    .marker {
        background-color: #FFF2E7;
    }

    .marker>p,
    .item_val {
        color: #E25600;
    }

    .item_details {
        background-color: #FFF2E7;
    }
}