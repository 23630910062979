@use '../../colours';

#loading_panel,
#loading_panel_buy {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5em;
    height: calc(100vh - 100px);
    margin-bottom: 3em;

    #loading_text {
        display: flex;
        flex-flow: column nowrap;
        align-items: baseline;
        gap: 0.5em;
        width: 60%;

        h1 {
            font-family: 'Quattrocento', 'Georgia', serif;
            font-style: normal;
            font-weight: 700;
            text-align: left;
            width: 100%;
        }

        h2 {
            font-family: 'Nunito Sans', sans-serif;
            font-weight: 600;
            margin: 0;
            margin-bottom: 16px;
        }


        #plate {
            background: #FEC84B;
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
            padding: 12px 16px;
            align-items: center;
            gap: 4px;
            border-radius: 4px;
            width: 190px;
            text-align: center;

            p {
                font-size: 32px;
                font-family: 'Nunito Sans', sans-serif;
                font-style: normal;
                font-weight: 800;
                line-height: 28px;
                text-transform: uppercase;
            }
        }

    }


    #loading_animation {
        width: 40%;
        height: 50%;
        display: flex;
        flex-flow: row nowrap;
        gap: 4px;
        

        .bar {
            flex-grow: 1;
            background: #fff;
            display: inline-block;
            transform-origin: bottom center;
            border-radius: 32px 32px 5px 5px;
            animation: loader 2s linear infinite;
        }

        .bar1 {
            animation-delay: 0.1s;
        }

        .bar2 {
            animation-delay: 0.2s;
        }

        .bar3 {
            animation-delay: 0.3s;
        }

        .bar4 {
            animation-delay: 0.4s;
        }

        .bar5 {
            animation-delay: 0.5s;
        }

        .bar6 {
            animation-delay: 0.6s;
        }

        .bar7 {
            animation-delay: 0.7s;
        }

        .bar8 {
            animation-delay: 0.8s;
        }

        @keyframes loader {
            0% {
                transform: scaleY(0.1);
                background: transparent;
            }

            50% {
                transform: scaleY(1);
                background: colours.$primary;
            }

            100% {
                transform: scaleY(0.1);
                background: transparent;
            }
        }
    }

}

// RED
@media only screen and (min-width: 1240px) {

    #loading_panel,
    #loading_panel_buy {
        width: 1128px;
        margin-bottom: 128px;
        flex-flow: row nowrap;

        h1 {
            margin: 128px 0 8px 0;
            font-size: 57px;
            line-height: 64px;
            letter-spacing: -0.25px;
        }

        h2{
            font-size: 45px;  
        }
    }
}

@media only screen and (min-width: 1240px) and (max-width: 1439px) {
    #loading,
    #loading_panel {
        h1 {
            margin: 70px 0 8px 0 !important;
        }

    }
}

// YELLOW
@media only screen and (min-width: 905px) and (max-width: 1239px) {

    #loading_panel,
    #loading_panel_buy {
        width: calc(100% - 64px);
        margin-bottom: 96px;
        flex-flow: row nowrap;

        h1 {
            margin: 96px 0 8px 0;
            font-size: 45px;
            line-height: 52px;
        }


        h2{
            font-size: 32px;  
        }
    }
}

// GREEN
@media only screen and (min-width: 600px) and (max-width: 904px) {
    #loading_panel,
    #loading_panel_buy {
        width: calc(100% - 32px);
        margin-bottom: 64px;
        flex-flow: row nowrap;
        margin-top: 50px;

        h1 {
            margin: 96px 0 8px 0;
            font-size: 36px;
            line-height: 44px;
        }

        h2{
            font-size: 26px;  
        }
    }
}

// BLUE
@media only screen and (max-width: 599px) {
   #loading_panel,
   #loading_panel_buy {
        width: calc(100% - 32px);
        margin-bottom: 48px;
        flex-flow: column nowrap;

        h1 {
            margin: 0 0 8px 0;
            font-size: 36px;
            line-height: 44px;
        }

        h2{
            font-size: 26px;  
        }

        #loading_text {
            width: 100%;
        }

        #loading_animation{
            margin: 100px 0 8px 0;
            width: 80%;
            height: 30%;
            order: -1;
        }
    }
}