@use '../../colours';

#eco_panel {

    .key_val_cont {
        margin-left: 16px;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
        background-color: #E3EAE7;
        border-radius: 16px;
        padding: 12px 20px 12px 16px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 8px;

        p {
            color: colours.$primary;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.1px;
            padding-top: 2px;
        }

        .blur {
            color: transparent !important;
            text-shadow: 0 0 6px colours.$primary !important;
        }
    }

    #fuel_economy {
        display: flex;
        flex-flow: column nowrap;
        gap: 24px;

        .sep {
            border-bottom: 1px solid colours.$sep;
            margin: 16px 0;
        }

        #combined {
            margin-bottom: 24px;
        }

        .key_cont {
            display: flex;
            flex-flow: row nowrap;
            gap: 4px;
            align-items: center;
        }

        #urban,
        #combined,
        #extra_urban {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;
            padding: 0 6px;

            .blur:not(.key_val_cont>.blur) {
                color: transparent !important;
                text-shadow: 0 0 7px rgba(0, 0, 0, 1) !important;
            }
        }
    }

    #emissions {
        display: flex;
        flex-flow: column nowrap;

        .sep {
            border-bottom: 1px solid colours.$sep;
            margin: 16px 0;
        }

        #emissions_main_row {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            gap: 4px;
            align-items: center;
            margin-bottom: 24px;

            .default_key {
                padding-left: 6px;
            }
        }

        .emissions_row {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;
            padding: 0 6px;

            .row_key {
                display: flex;
                flex-flow: row nowrap;
                gap: 4px;
            }

            .blur {
                color: transparent !important;
                text-shadow: 0 0 7px rgba(0, 0, 0, 1) !important;
            }
        }
    }

    #green_ncap {
        display: flex;
        flex-flow: column nowrap;
        gap: 32px;

        .key_val_cont {
            min-width: 70px;
        }

        #ncap_summary {
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            align-items: flex-start;
            gap: 24px;

            q {
                font-style: normal;
                font-weight: 600;
                color: #3F4946;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.25px;
            }

            #rating_cont {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                #prefix {
                    display: flex;
                    flex-flow: row nowrap;
                    gap: 8px;
                }

                p:not(.key_val_cont>p, .tt_text_p) {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 20px;
                    letter-spacing: 0.25px;
                }
            }
        }

        #ncap_details {
            display: flex;
            padding-bottom: 8px;
            gap: 24px;
            flex-flow: column nowrap;

            .detail_item {
                display: flex;
                flex-flow: column nowrap;
                gap: 8px;

                .ncap_text {
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    justify-content: space-between;

                    p {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 0.25px;
                    }
                }

                .ncap_bar {
                    height: 4px;
                    width: 100%;
                    background-color: #DDE4E1;

                    .ncap_bar_inner {
                        height: 4px;
                        background-color: #00D968;
                    }
                }
            }
        }

        .blur {
            color: transparent !important;
            text-shadow: 0 0 7px rgba(0, 0, 0, 1) !important;
        }

        .blur_green {
            color: transparent !important;
            text-shadow: 0 0 7px colours.$primary !important;
        }

        span {
            text-shadow: none !important;
            color: colours.$off_black !important;
        }
    }

    #zone_check {

        display: flex;
        flex-flow: column nowrap;

        #zone_header {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 16px;
        }

        .hide {
            display: none !important;
        }

        .sep {
            border-bottom: 1px solid colours.$sep;
            width: 100%;
            margin: 16px 0 8px 0;
        }

        #zone_dropdown {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            padding: 8px 4px;
            cursor: pointer;

            img {
                transition: .15s ease-in-out;
            }
        }

        #zone_table {
            margin: 16px 0;
            padding: 16px;
            display: flex;
            flex-flow: column nowrap;
            background-color: #EFF1EF;
            border-radius: 12px;

            .blur {
                color: transparent !important;
                text-shadow: 0 0 7px #000 !important;
            }

            .zone_row {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
                padding: 8px 6px;

                .zone_key {
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    gap: 8px;
                }
            }
        }
    }

    #eco_score {
        display: flex;
        flex-flow: column nowrap;

        #score_switcher {
            display: flex;
            flex-flow: row nowrap;
            gap: 8px;
            justify-content: center;
            align-items: center;
            margin-top: 8px;

            div {
                padding: 10px 24px;
                border-radius: 100px;
                outline: none;
                transition: .15s ease-in-out;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0.1px;
                cursor: pointer;
                text-align: center;
            }

            .inactive {
                border: 1px solid #6F7976;
                color: colours.$primary;
                background-color: none;
            }

            .active {
                background-color: #CCE8E2;
                border: 1px solid transparent;
            }
        }

        .hide {
            display: none !important;
        }

        .sep {
            border-bottom: 1px solid colours.$sep;
            width: 100%;
            margin: 16px 0 8px 0;
        }

        #eco_dropdown {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            padding: 8px 4px;
            cursor: pointer;

            img {
                transition: .15s ease-in-out;
            }
        }

        #eco_table {
            margin: 16px 0;
            padding: 16px;
            display: flex;
            flex-flow: column nowrap;
            background-color: #EFF1EF;
            border-radius: 12px;

            .blur {
                color: transparent !important;
                text-shadow: 0 0 7px #000 !important;
            }

            .eco_row {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
                padding: 8px 6px;

                .eco_key {
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    gap: 4px;
                }

                .val_cont{
                    display: flex;
                    flex-flow: row nowrap;
                    gap: 8px;
                    align-items: center;

                    .dot{
                        width: 8px;
                        height: 8px;
                        border-radius:100px;
                        margin-right: 4px;
                    }

                    .default_val{
                        text-align: end;
                    }

                    .bad_score_dot{
                        background-color: #f02320 !important;
                    }
    
                    .ok_score_dot{
                        background-color: #e48e1d !important;
                    }
    
                    .good_score_dot{
                        background-color: #40ae49 !important;
                    }
                }

                
            }
        }

    }

    #noise {
        display: flex;
        flex-flow: column nowrap;

        .sep {
            border-bottom: 1px solid colours.$sep;
            margin: 16px 0;
        }

        .blur:not(.key_val_cont>.blur) {
            color: transparent !important;
            text-shadow: 0 0 7px rgba(0, 0, 0, 1) !important;
        }

        #noise_main_row {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            gap: 4px;
            align-items: center;
            margin-bottom: 24px;

            .default_key {
                padding-left: 6px;
            }
        }

        .noise_row {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;
            padding: 0 6px;
        }
    }

}

// BLUE - 100% - 32px
@media only screen and (max-width: 300px) {
    #eco_table {
        padding: 8px !important;
    }
}

