@use '../../colours';

.list_ad {
    display: flex;
    flex-flow: column nowrap;
    padding: 24px;
    gap: 16px;
    border-radius: 28px;
    background-color: #E3EAE7;

    .list_ad_title {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        text-align: center;
    }

    .list_container{
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-around;
        gap: 24px;

        p{
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.25px;
        }

        .list_col{
            display: flex;
            flex-flow: column nowrap;
            
            .list_item{
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: space-between;
                gap: 16px;
                height: 40px;
            }
        }
    }

}

// BLUE - 100% - 32px
@media only screen and (min-width: 600px) {
    .list_ad {
        display: none;
    }
}