@use '../colours';

#cookie_banner.hide {
    display: none;
}

#cookie_banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: colours.$tertiary;
    z-index: 1000;
    height: 40px;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    #cookie_inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: left;
        gap: 8px;

        #btns {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;

            #accept_btn,
            #decline_btn {
                font-family: 'Nunito Sans', sans-serif;
            }
        }

        p,
        a {
            color: colours.$off_black;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.1px;
        }
    }
}


// RED - 1128px
@media only screen and (min-width: 1240px) {
    #cookie_banner {
        padding: 8px 0;
    }

    #cookie_inner {
        width: 1128px;
    }

    html:not(:has(#main_report)) {
        body:not(:has(#cookie_banner.hide)) {
            #hubspot-messages-iframe-container {
                transform: translateY(-56px);
                max-height: calc(100% - 56px);
            }
        }
    }
}

// YELLOW - calc(100% - 32px);
@media only screen and (min-width: 905px) and (max-width: 1239px) {
    #cookie_banner {
        padding: 8px 0;
    }

    #cookie_inner {
        width: calc(100% - 32px);

        // #btns {
        //     padding-right: 80px;
        // }
    }

    html:not(:has(#main_report)) {

        body:not(:has(#cookie_banner.hide)) {
            #hubspot-messages-iframe-container {
                transform: translateY(-56px);
                max-height: calc(100% - 56px);
            }
        }
    }
}

// GREEN - calc(100% - 32px);
@media only screen and (min-width: 600px) and (max-width: 904px) {
    #cookie_banner {
        padding: 16px 0;
    }

    #cookie_inner {
        width: calc(100% - 32px);

        // #btns {
        //     padding-right: 70px;
        // }
    }

    html:not(:has(#main_report)) {

        body:not(:has(#cookie_banner.hide)) {
            #hubspot-messages-iframe-container {
                transform: translateY(-72px);
                max-height: calc(100% - 72px);

            }
        }
    }
}

// BLUE - calc(100% - 32px);
@media only screen and (max-width: 599px) {
    #cookie_banner {
        padding: 24px 0;
        height: 80px;
    }

    #cookie_inner {

        p,
        a {
            font-size: 12px !important;
        }

        width: calc(100% - 32px);

        flex-flow: column nowrap;

        #btns {
            align-self: baseline;
            // padding-right: 70px;
            // flex-flow: column nowrap;
        }
    }
}