@use '../colours';

.report_frame {
    display: flex;
    border-radius: 12px;
    flex-flow: column nowrap;

    #prem_unlock {
        font-family: 'Nunito Sans', sans-serif;
    }

    .header_cont {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 8px;
    }

    .frame_header {
        padding: 8px 16px;
        border-radius: 12px 12px 0 0;
        height: 48px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        gap: 8px;

        .title_parts {
            display: flex;
            flex-flow: column nowrap;

            h2 {
                margin: 0;
                font-size: 16px;
                font-style: normal;
                font-weight: 800;
                line-height: 24px;
                letter-spacing: 0.15px;
            }

            h3,
            a {
                margin: 0;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0.25px;
            }

            a {
                color: colours.$off_black;
            }
        }

        .marker {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            border-radius: 100px;
            padding: 10px 16px;

            img {
                width: 18px;
                height: 18px;
            }
        }

        p:not(.tt_text_p) {
            text-align: right;
        }

        .back_btn {
            display: none;
        }

        .back_btn.show {
            user-select: none;
            display: flex !important;
            flex-flow: row nowrap;
            cursor: pointer;
            gap: 1em;
            color: rgb(64, 64, 64);
            font-size: calc(12px + 0.2vw);
            align-items: center;

            img {
                width: 20px;
                height: 20px;
            }
        }

    }

    .frame_content.free_check {
        position: relative;
    }

    .frame_content {
        background-color: white;
        border-radius: 0 0 12px 12px;
        padding: 16px;
    }
}

// BLUE - 100% - 32px
@media only screen and (max-width: 319px) {
    .report_frame {
        .frame_header.free_check {
            .title_parts {
                
                h2 {
                    inline-size: 71px;
                    overflow-wrap: break-word;
                }
            }
        }

        .frame_header {
            padding: 8px;
        }
    }
}