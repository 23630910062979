@use '../colours';

#main_buy {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    .stage {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        width: 100%;
    }
}