@use '../../colours';

#history_panel {

    .full_title {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.25px;
    }

    .history_table :first-child :first-child.header_row{
        padding-top: 4px !important
    }

    .history_table {
        display: flex;
        flex-flow: column nowrap;
        flex-grow: 1;
        padding: 8px 0;

        .img_expand {
            transition: .15s ease-in-out;
        }

        .title_cont {
            display: flex;
            flex-flow: row nowrap;
            gap: 4px;
            padding: 8px 16px 8px 0;
            cursor: default !important;
        }

        .header_row {
            transition: .1s ease-in-out;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            padding: 16px 6px 0 6px;
            cursor: pointer;

            .full_title {
                font-weight: 700;
                display: block !important;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .marker_container {
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                gap: 8px;
                cursor: pointer;

                .marker_img {
                    width: 18px;
                    height: 18px;
                }

                .full_marker {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    padding: 8px 16px;
                    border-radius: 100px;

                    p {
                        transform: translateY(1px);
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 0.25px;
                    }

                }

                .full_marker_pass {
                    background-color: colours.$secondary;

                    p {
                        color: colours.$primary;
                    }
                }

                .full_marker_fail {
                    background-color: colours.$fail_bg;

                    p {
                        color: colours.$fail;
                    }
                }

                .full_marker_nodata,
                .full_marker_warn {
                    background-color: #FFF2E7;

                    p {
                        color: #E25600;
                    }
                }
            }
        }

        .content {
            background-color: #EFF1EF;
            border-radius: 12px;
            padding: 16px;
            margin-top: 12px;

            .pass_card>p {
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0.5px;
                color: colours.$primary;
            }

            .premium_card {
                display: flex;
                flex-flow: column nowrap;
                justify-content: center;
                align-items: center;

                #footer_btn {
                    --md-filled-button-container-color: colours.$fail;
                }

                .footer_title {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    letter-spacing: 0.5px;
                    text-align: center;
                }

                p:not(.footer_title) {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 0.25px;
                    color: #3F4946;
                    text-align: center;
                    margin-bottom: 16px;
                }

                #footer_btn {
                    font-family: 'Nunito sans', sans-serif;
                }

            }

            .fail_data {
                display: flex;
                flex-flow: column nowrap;
                gap: 16px;

                h3 {
                    margin: 0;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 0.25px;
                    color: colours.$fail;
                }

                .details_row {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 6px;

                    .details_key {
                        display: flex;
                        flex-flow: row nowrap;
                        align-items: center;
                        gap: 8px;
                        height: 36px;
                    }
                }

                .details_row:not(.stack)>.default_val {
                    max-width: 75%;
                    text-align: right;
                }

                .stack {
                    flex-flow: column nowrap;
                    gap: 2px;
                    margin-top: 8px;

                    .details_key {
                        width: 100%;
                        height: auto;
                    }

                    .default_val {
                        max-width: none;
                        width: 100%;
                        text-align: left !important;
                    }
                }

                .entry_cont {
                    flex-grow: 1;

                    .entry_header {
                        display: flex;
                        flex-flow: column nowrap;

                        .sep {
                            margin: 8px 0;
                            border-bottom: 1px solid #CAC4D0;
                        }


                    }

                }

                .entry_cont_dropdown {
                    border-radius: 12px;

                    .entry_header {
                        display: flex;
                        flex-flow: row nowrap;
                        align-items: center;
                        justify-content: space-between;

                        .expand {
                            display: flex;
                            flex-flow: row nowrap;
                            justify-content: space-between;
                            border: 1px solid colours.$sep;
                            border-radius: 100px;
                            padding: 4px 12px 4px 16px;
                            gap: 8px;
                            width: 115px;

                            p {
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 20px;
                                letter-spacing: 0.25px;
                            }
                        }
                    }

                    .row_cont {
                        border-top: 1px solid #CAC4D0;
                        display: none;
                        flex-flow: column nowrap;
                        padding-top: 8px;
                        margin-top: 16px;
                    }

                    .row_cont.active {
                        display: flex;
                    }
                }

            }
        }

        .sep {
            width: 100%;
            border-bottom: 1px solid colours.$sep;
            margin-top: 16px;
        }
    }

    .history_columns {
        display: flex;

        .report_frame {
            flex-grow: 1;
            flex-basis: 0;
            height: fit-content;
        }
    }
}


// GREEN - 100% - 32px
@media only screen and (min-width: 600px) {
    #history_panel {

        .history_columns {
            gap: 24px;
            flex-flow: row nowrap;
        }

        .history_table .title_cont {
            max-width: 19vw;
        }
    }
}

// BLUE - 100% - 32px
@media only screen and (min-width: 331px) and (max-width: 599px) {
    #history_panel {

        .history_columns {
            gap: 16px;
            flex-flow: column nowrap;
        }

        .history_table .title_cont {
            max-width: 40vw;
        }
    }
}

@media only screen and (max-width: 330px) {
    #history_panel {

        .history_columns {
            gap: 16px;
            flex-flow: column nowrap;
        }
        
        .history_table .title_cont {
            max-width: 30vw !important;
        }
    }
}