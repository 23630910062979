@use '../colours';

.plate_input_cont {
    display: flex;
    flex-flow: row nowrap;
    max-width: 90vw;
    background-color: colours.$plate;
    padding: 4px 4px 4px 16px;
    justify-content: space-between;
    border-radius: 8px 100px 100px 8px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
    width: max-content;
    display: flex;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    max-height: 40px;

    * {
        font-family: 'Nunito Sans', sans-serif;
    }

    .button{
        font-weight: 700 !important;
    }

    .loading_btn_cont {
        border-radius: 100px;
        padding: 10px 12px;
        border: none;
        background-color: colours.$primary;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        gap: 10px;

        p {
            font-weight: 600;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.1px;
            color: white;
        }

        @keyframes jumpingAnimation {
            0% {
                height: 8px
            }

            50% {
                height: 20px;
            }

            100% {
                height: 8px
            }
        }

        .dot_cont {
            displaY: flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: 3px;

            .dot {
                display: inline-block;
                width: 4px;
                height: 7px;
                border-radius: 7px;
                background-color: white;
            }
        }

        .dot_cont .dot:nth-last-child(1) {
            animation: jumpingAnimation 0.8s 0.1s ease-in infinite;
        }

        .dot_cont .dot:nth-last-child(2) {
            animation: jumpingAnimation 0.8s 0.2s ease-in infinite;
        }

        .dot_cont .dot:nth-last-child(3) {
            animation: jumpingAnimation 0.8s 0.3s ease-in infinite;
        }
    }

    .plate_input_elem {
        background-color: colours.$plate;
        border: none;
        flex-grow: 1;
        font-size: 1.2rem;
        font-weight: 800;
        width: 100%;
        text-transform: uppercase;
        max-width: 13rem;
        margin-top: 0.1em;
    }

    input::placeholder {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #4D635E;
        transform: translateY(0px);
        text-transform: none;
    }

    input:focus {
        outline: none;
    }
}