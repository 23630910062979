@use '../colours';

#main_doc {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;


    section {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        width: calc(25% + 700px);
    }

    iframe {
        width: calc(451.4pt + 72pt + 72pt);
        border: none;
    }

    iframe.t_and_c {
        height: 8000px;
    }

    iframe.cookies {
        height: 2900px;
    }

    iframe.privacy {
        height: 8500px;
    }

}

@media only screen and (max-width: 700px) {
    #main_doc {

        section {
            width: 120%;
        }

        iframe.t_and_c {
            height: 9100px;
        }

        iframe.cookies {
            height: 3200px;
        }

        iframe.privacy {
            height: 9600px;
        }
    }
}

@media only screen and (max-width: 600px) {
    #main_doc {

        section {
            width: 130%;
        }
    }
}


@media only screen and (max-width: 500px) {
    #main_doc {

        section {
            width: 140%;
        }
    }
}


@media only screen and (max-width: 400px) {
    #main_doc {

        section {
            width: 150%;
        }
    }
}

@media only screen and (max-width: 300px) {
    #main_doc {

        section {
            width: 160%;
        }
    }
}