#emissions_bar_plot {
    display: flex;
    flex-flow: column nowrap;
    gap: 4px;
    margin-bottom: 24px;
    align-items: flex-end;

    .bar {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        flex-grow: 1;
        align-items: center;
        border-radius: 50px 50px 50px 50px;
        min-height: 12px;

        img{
            max-height: 18px;
            margin-left: 16px;
        }

        .car {
            font-size: 16px;
            font-style: normal;
            font-weight: 800;
            line-height: 24px;
            letter-spacing: 0.15px;
        }

        .rating {
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.25px;
            color: #49454F;
            margin-right: 16px;
            min-width: 28px;
        }

        #target_row{
            display: flex;
            flex-flow: row nowrap;
            gap: 8px;
            align-items: center;
        }
    }

    .bar.active{
        border-radius: 50px 24px 24px 50px;
        width: 100% !important;
        padding: 8px 0;
    }
}