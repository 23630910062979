@use '../../colours';

.free_check_cta {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    background-color: #E3EAE7;
    text-align: center;
    padding-bottom: 24px;
    border-radius: 28px;
    margin-bottom: 24px;

    #tp_link{
        margin: 0 8px;
        a{
            font-size: 14px !important;
        }
    }
    
    h3{
        margin: 24px;
    }

    h3 {
        margin-bottom: 16px;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
    }

    p.text {
        margin: 0 16px 16px 16px;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.25px;

    }

    p.price {
        margin: 0 16px;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.25px;
    }

    a>p {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.1px;
        text-decoration: none;
    }

    a{
        text-decoration: none;
    }

    .cta_header {
        width: 100%;
        max-height: 122px;
        min-height: 122px;
        object-fit: cover;
        border-radius: 28px 28px 0 0;

    }

    .cta_footer {
        display: flex;
        flex-flow: column nowrap;
        gap: 16px;
        align-items: center;
        #cta_btn {
            font-family: 'Nunito Sans', sans-serif;
            --md-filled-button-container-color: #006B5F;
            width: fit-content;
        }
    }
}