@use '../../colours';

.blog_card {
    flex: 1 0 0;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 12px;
    background-color: colours.$secondary;
    border: 1px solid colours.$sep;
    // min-width: ;

    img.title_img {
        flex-grow: 1;
        max-width: 100%;
        object-fit: cover;
        max-width: 100%;
        border-radius: 12px 12px 0 0;
    }

    div.title_img {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        background-color: #CCE8E2;
        width: 100%;
        border-radius: 12px 12px 0 0;

        p {
            color: #FFF !important;
            text-align: center;
            font-family: 'Quattrocento', Georgia, serif;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: 44px;
            margin: 0;
        }
    }

    .marker {
        padding: 10px;

        p:not(.title_img>p) {
            color: colours.$primary;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.1px;
        }
    }

    .content {
        padding: 16px;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        flex-grow: 1;
    }

    h2 {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.5px;
        margin: 0;
    }

    .date {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #3F4946;
    }

    .subtitle {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #3F4946;
        margin: 32px 0;
    }

    #blog_btn {
        font-family: 'Nunito Sans', sans-serif;
    }

    .card_footer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        justify-self: flex-end;
    }
}

.blog_card:not(.live) {

    h2, .subtitle, .date,
    .marker {
        opacity: 0.4;
    }

    a {
        pointer-events: none;
    }
}


// RED - 1128px
@media only screen and (min-width: 1240px) {
    .blog_card {
        width: 30%;
        min-width: 30%;
        max-width: 30%;

        img.title_img {
            height: 260px;
        }

        div.title_img {
            height: 260px;
        }
    }
}

// YELLOW - 100% - 64px
@media only screen and (min-width: 905px) and (max-width: 1239px) {

    .blog_card{
        width: 30%;
    }

    img.title_img {
        height: 240px;
    }

    div.title_img {
        height: 240px;
    }
}

// GREEN - 100% - 32px
@media only screen and (min-width: 600px) and (max-width: 904px) {

    .blog_card{
        min-width: 250px;
        max-width: 280px;
    }

    img.title_img {
        height: 240px;
    }

    div.title_img {
        height: 240px;
        p{
            padding: 16px;
        }    }
}

// BLUE - 100% - 32px
@media only screen and (max-width: 599px) {
    .blog_card{
        width: 100%;
        // max-width: 280px;
    }

    img.title_img {
        height: 188px;
        width: 100%;
    }

    div.title_img {
        height: 188px;
        p{
            padding: 16px;
        }
    }
}