@use '../colours';

#exit_intent {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 998;

    .underlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 998;
        background: rgba(52, 64, 84, 0.65);
        cursor: pointer;
        -webkit-animation: fadein 0.5s;
        animation: fadein 0.5s;
        backdrop-filter: blur(6px);
    }

    .popup {
        display: flex;
        flex-flow: column nowrap;
        height: fit-content;
        background-color: white;
        z-index: 999;
        position: absolute;
        margin: auto;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 28px;
        -webkit-animation: popin 0.3s;
        animation: popin 0.3s;
        width: 312px;
        max-width: 312px;
        justify-content: space-between;
        align-items: center;
        padding: 24px 0;
        background-color: #E3EAE7;

        #title {
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
            padding: 0 24px;
            align-self: flex-start;
            font-family: 'Quattrocento', Georgia, 'Times New Roman', Times, serif;
        }

        #subtitle {
            color: #3F4946;
            font-family: 'Quattrocento', Georgia, 'Times New Roman', Times, serif;
            font-size: 57px;
            font-style: normal;
            font-weight: 700;
            line-height: 64px;
            letter-spacing: -0.25px;
            margin: 16px 0 32px 0;
            padding: 0 24px;
            align-self: flex-end;
        }

        #code {
            height: 48px;
            background-color: #DDE4E1;
            width: 100%;
            border-bottom: 1px solid #3F4946;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            text-align: center;

            p {
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0.5px;
                color: #3F4946;
                padding: 0 8px;

            }

            span {
                color: #BA1A1A !important;
            }

        }

        #responses {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: flex-end;
            gap: 6px;

            #yes {
                transition: .1s ease-in-out;
                background-color: colours.$primary;
                border-radius: 100px;
                outline: none;
                color: white;
                border: none;
                padding: 10px 24px;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0.1px;
                margin-top: 48px;
                cursor: pointer;
            }

            #yes:hover {
                opacity: 0.95;
            }

            #no {
                cursor: pointer;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0.1px;
                color: colours.$primary;
                background-color: transparent;
                outline: none;
                border: none;
                padding: 10px 12px;
            }
        }

    }
}

// BLUE - 100% - 32px
@media only screen and (max-width: 599px) {
    #exit_intent {
        #subtitle {
            font-size: 45px !important;
        }
    }
}

.hide {
    display: none;
}

@-webkit-keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes popin {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }

    85% {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

@-ms-keyframes popin {
    0% {
        -ms-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }

    85% {
        -ms-transform: scale(1.05);
        transform: scale(1.05);
        opacity: 1;
    }

    100% {
        -ms-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes popin {
    0% {
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }

    85% {
        -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
        transform: scale(1.05);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

@media only screen and (max-width: 599px) {
    #exit_intent {
        .popup {
            width: 80%;
        }
    }
}