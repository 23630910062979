@use '../colours';

.non_frame {
    border-radius: 12px;
}

.blocker_cont:not(.non_frame) {
    border-radius: 0 0 12px 12px;
}

.blocker_cont {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    backdrop-filter: blur(7px);
    background: rgba(179, 185, 195, 0.09);
    pointer-events: none;
    z-index: 10;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    pointer-events: all !important;


    .modal {
        border: 1px solid colours.$sep;
        display: flex;
        flex-flow: column nowrap;
        background-color: white;
        border-radius: 28px;
        text-align: center;
        z-index: 999;
        align-items: center;
        justify-content: center;
        padding: 24px;

        img {
            width: 24px;
        }

        p {
            margin: 8px 0 16px 0;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0.15px;
        }

        #prem_unlock {
            font-family: 'Nunito Sans', sans-serif;
        }
    }
}



// RED - 1128px
@media only screen and (min-width: 1240px){
    .blocker_cont {
        .modal {
            min-width: 312px;
        }
    } 
}

// YELLOW - 100% - 64px
@media only screen and (min-width: 905px) and (max-width: 1239px) {
    .blocker_cont {
        .modal {
            min-width: 312px;
        }
    }
}

// GREEN - 100% - 32px
@media only screen and (min-width: 600px) and (max-width: 904px) {
    .blocker_cont {
        .modal {
            min-width: 312px;
        }
    }
}

// BLUE - 100% - 32px
@media only screen and (max-width: 599px) {
    .blocker_cont {
        .modal {
            width: 75%;
            max-width: 312px;
        }
    }
}