@use '../colours';

#main_blog {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    #articles {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        
    }

    .main_h1 {
        font-family: 'Quattrocento', Georgia, serif;
        font-style: normal;
        font-weight: 700;
        text-align: left;
        margin: 0;
    }

}


// RED - 1128px
@media only screen and (min-width: 1240px) {
    #main_blog {

        .main_h1 {
            width: 1128px;
            margin-top: 80px;
            padding-top: 128px;
            font-size: 57px;
            line-height: 64px;
            letter-spacing: -0.25px;
            margin-bottom: 56px;
        }

        #articles {
            width: 1128px;
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            margin-bottom: 168px;
            gap: 24px;
        }
    }

}

// YELLOW - 100% - 64px
@media only screen and (min-width: 905px) and (max-width: 1239px) {
    #main_blog {

        .main_h1 {
            width: calc(100% - 64px);
            margin-top: 80px;
            padding-top: 96px;
            font-size: 45px;
            line-height: 52px;
            margin-bottom: 56px;
        }

        #articles {
            width: calc(100% - 64px);
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            margin-bottom: 136px;
            gap: 24px;

        }
    }
}

// GREEN - 100% - 32px
@media only screen and (min-width: 600px) and (max-width: 904px) {
    #main_blog {
        .main_h1 {
            width: calc(100% - 32px);
            margin-top: 80px;
            padding-top: 64px;
            font-size: 36px;
            line-height: 44px;
            margin-bottom: 56px;

        }

        #articles {
            width: calc(100% - 32px);
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            margin-bottom: 164px;
            gap: 24px;
        }
    }
}

// BLUE - 100% - 32px
@media only screen and (max-width: 599px) {
    #main_blog {

        .main_h1 {
            width: calc(100% - 32px);
            margin-top: 88px;
            padding-top: 48px;
            font-size: 36px;
            line-height: 44px;
            margin-bottom: 40px;
        }

        #articles {
            width: calc(100% - 32px);
            display: flex;
            flex-flow: column nowrap;
            // justify-content: flex-start;
            margin-bottom: 164px;
            gap: 24px;

        }
    }
}