@use '../colours';

.tooltip {
    display: flex;
    flex-flow: nowrap;

    img {
        width: 18px !important;
    }

    .tt_text {
        position: absolute;
        padding: 1em;
        background: colours.$off_black;
        border-radius: 8px;
        border: 1px solid colours.$grey_border_dark;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
        font-size: calc(12px + 0.2vw) !important;
        z-index: 900;
        white-space: pre-line;
        font-weight: 500 !important;
        display: flex;
        flex-flow: column nowrap;
        gap: 1em;

        p {
            color: white;
        }

        .close_btn {
            align-self: center;
            border: 1px solid white;
            border-radius: 8px;
            padding: 0.3em 0.8em;

            p {
                padding: 0 !important;
            }
        }
    }
}


@media only screen and (max-width: 750px) {
    .tooltip {
        .tt_text {
            font-size: calc(12px + 0.7vw) !important;
        }
    }
}