@use '../../colours';

#spec_panel {

    #spec_content {
        display: flex;
        flex-flow: column nowrap;

        #search_results_cont {
            position: relative;
            width: calc(100% - 16px);
            align-self: center;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;

            #search_results {
                display: none;
                flex-flow: column nowrap;
                border: 1px solid colours.$grey_border_light;
                padding: 12px;
                border-radius: 12px;
                z-index: 999;
                background-color: white;
                position: absolute;
                width: calc(100% - 32px);
                margin-top: -8px;

                .result_item {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: space-between;
                    align-items: center;
                    padding: 8px 12px;
                    border-radius: 8px;
                }

                .result_item:hover {
                    cursor: pointer;
                    background-color: rgba(14, 147, 132, 0.3);
                }
            }
        }

        #search_bar {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            margin-bottom: 16px;
            border-radius: 28px;
            background-color: #E3EAE7;
            height: 48px;
            padding: 0 16px;
            gap: 8px;

            input {
                flex-grow: 1;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0.5px;
                background: none;
                border: none;
                outline: none;
                font-family: "Nunito Sans", sans-serif;
            }

            input::placeholder {
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0.5px;
                font-family: "Nunito Sans", sans-serif;
            }

        }


        .spec_item {
            display: flex;
            flex-flow: column nowrap;

            .data_header.free_check {
                cursor: default !important;
            }

            .data_header {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                padding: 0 6px;
                height: 46px;
                cursor: pointer;

                .header_left {
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    gap: 4px;
                    padding-right: 8px;
                    cursor: default;
                }

                .header_right {
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    gap: 4px;

                    .img_expand {
                        transition: .15s ease-in-out;
                    }

                    .marker {
                        padding: 4px 16px;
                        background-color: colours.$neutral;
                        border-radius: 100px;

                        .blur {
                            color: transparent !important;
                            text-shadow: 0 0 7px colours.$primary !important;
                        }

                        p {
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 20px;
                            letter-spacing: 0.25px;
                            color: colours.$primary;
                            text-align: center;
                        }
                    }
                }
            }

            .sep {
                border-bottom: 1px solid colours.$sep;
                width: 100%;
                margin: 8px 0;
            }

            .data_content {
                background-color: #EFF1EF;
                border-radius: 12px;
                padding: 16px;
                display: flex;
                flex-flow: column nowrap;
                margin: 8px 0;

                .result {
                    background-color: rgba(0, 107, 95, 0.3);
                }

                p {
                    user-select: auto !important;
                }

                .list_sep {
                    border-bottom: 1px solid colours.$sep;
                    width: 100%;
                    margin: 8px 0;
                }

                .content_row {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: space-between;
                    padding: 8px 6px;
                    transition: 1s;
                    border-radius: 8px;
                    gap: 4px;
                }

                .key_cont {
                    display: flex;
                    flex-flow: row nowrap;
                    gap: 4px;
                    align-items: center;
                }

                .default_val {
                    text-align: right;
                }
            }

            .data_content.hide {
                display: none;
            }

        }
    }
}