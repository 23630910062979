@use '../colours';

#report_mob_nav :first-child.mob_item {
    margin-left: 8px;
}

#report_mob_nav :last-child.mob_item {
    margin-right: 8px;
}

#report_mob_nav_main {
    z-index: 998;
    width: 100%;
}

#report_mob_nav {
    background-color: colours.$neutral_dark;
    position: fixed;
    bottom: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    gap: 8px;
    overflow-x: scroll;

    .mob_item:has(.alert) {

        .icon {
            padding: 4px 4px;

            img {
                margin-left: 16px !important;

            }
        }

    }

    .mob_item {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        gap: 4px;
        padding: 12px 0 16px 0;
        min-width: 68px;
        max-width: 68px;

        p {
            transition: .2s ease-in-out;
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
            line-height: 16px;
            letter-spacing: 0.5px;
        }

        .icon {
            transition: .2s ease-in-out;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            height: 32px;
            padding: 4px 20px;
            border-radius: 16px;

            .alert {
                border-radius: 100px;
                background: colours.$fail;
                align-self: flex-start;
                width: 18px !important;
                height: 18px !important;
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                align-items: center;
                transform: translate(-8px, -6px);

                p {
                    color: white;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 16px;
                    letter-spacing: 0.5px;
                }
            }
        }
    }

    .mob_item.active {
        .icon {
            background-color: #B1CCC6;
        }

        p {
            font-weight: 700;
        }
    }
}

@media only screen and (min-width: 905px) {
    #report_mob_nav {
        display: none;
    }
}

@media only screen and (max-width: 904px) {
    #report_mob_nav {
        display: flex;
    }
}