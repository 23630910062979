@use '../colours';

.no_data {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    gap: 16px;
    height: 100%;
    width: 100%;
    padding: 0;
    min-height: 100px;

    p {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #523916;
        text-align: center;
    }
}