@use '../colours';


body:has(#main_report) {
    background-color: colours.$grey_bg_light;

    #hubspot-messages-iframe-container {
        display: none !important;
    }
}

#main_report {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    background-color: colours.$grey_bg_light;

    #report_mobile_header {
        background-color: colours.$quaterniary;
        margin-top: 10px;
        padding: 8px 16px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        width: calc(100% - 32px);
        align-items: center;
        min-height: 49px;
        gap: 5px;

        #report_mob_head_left {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            img {
                max-width: 70px;
                max-height: 45px;
            }

            #report_mob_head_info {
                display: flex;
                flex-flow: column nowrap;
                justify-content: center;
                padding: 3px 0;
            }

            #main {
                font-family: 'Quattrocento', Georgia, 'Times New Roman', Times, serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px;
            }

            #sub {
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
                letter-spacing: 0.4px;
            }

            p {
                color: white;
            }
        }

        #report_mob_head_plate {
            background-color: colours.$plate;
            height: min-content;
            border-radius: 4px;
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
            padding: 0px 16px;

            p {
                font-size: 16px;
                font-style: normal;
                font-weight: 800;
                line-height: 28px;
            }

        }
    }

    p,
    h1,
    h2,
    h3,
    h4,
    h5 {
        user-select: none;
    }

    .menu-items {
        margin-top: 55px;
    }

    .default_key {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.25px;
    }

    .default_val {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #3F4946;
    }

    #copy {
        text-align: center;
        width: 100%;
        background-color: colours.$grey_bg_light;
        margin-bottom: 30px;
        font-size: 15px;
        color: #c0c0c0;
    }

    #section_report {
        margin-top: 56px;
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
        align-items: center;
        background-color: colours.$grey_bg_light;

        .highcharts_cont {
            width: 100%;
            margin: 0 auto;
            overflow-x: auto;
        }

        .report_panel {
            display: flex;
            flex-flow: column nowrap;
        }
    }

    #columns {
        display: flex;
        flex-flow: row nowrap;

        .col {
            display: flex;
            flex-flow: column nowrap;
        }

        .col.active {
            flex: 1
        }

        .col:not(.active) {
            flex: 0;
            display: none;
        }
    }
}

#mobile_header {
    #report_mob_head_left {
        gap: 24px;
    }
}

@media only screen and (min-width: 1920px) {
    #section_report {
        #columns {
            gap: 24px;

            .col {
                gap: 24px;
            }
        }

        .report_panel {
            margin-top: 24px;
            margin-bottom: 56px;
            width: 1128px;
            align-self: center;
        }

        .highcharts_cont {
            max-width: calc(((1128px - 48px) / 3) - 32px);
        }
    }
}

@media only screen and (min-width: 1612px) and (max-width: 1919px){
    #section_report {
        #columns {
            gap: 24px;

            .col {
                gap: 24px;
            }
        }

        .report_panel {
            margin-top: 24px;
            margin-bottom: 56px;
            width: 1128px;
            margin-left: 404px;
            align-self: flex-start;
        }

        .highcharts_cont {
            max-width: calc(((1128px - 48px) / 3) - 32px);
        }
    }
}

// RED - 1128px
@media only screen and (min-width: 1240px) and (max-width: 1611px) {
    #main_report {
        width: calc(100% - 324px) !important;
        align-self: flex-end;

        #section_report {
            #columns {
                gap: 24px;

                .col {
                    gap: 24px;
                }
            }

            .report_panel {
                margin-top: 24px;
                margin-bottom: 56px;
                width: calc(100% - 160px);
                // gap: 24px;
            }

            .highcharts_cont {
                max-width: calc(((1128px - 48px) / 3) - 32px);
            }
        }
    }
}

// YELLOW - 100% - 64px
@media only screen and (min-width: 905px) and (max-width: 1239px) {

    #main_report {
        width: calc(100% - 80px) !important;
        align-self: flex-end;
        
        
        #section_report {
            #columns {
                gap: 24px;

                .col {
                    gap: 24px;
                }
            }

            .report_panel {
                margin-top: 24px;
                margin-bottom: 56px;
                width: calc(100% - 64px);
                // gap: 24px;
            }
        }

        .highcharts_cont {
            max-width: calc(((100vw - 24px - 64px) / 2) - 32px - 80px);
        }
    }

}

// GREEN - 100% - 32px
@media only screen and (min-width: 600px) and (max-width: 904px) {
    #main_report{
        #copy {
            margin-bottom: 105px;
        }
    }
    
    #section_report {

        #columns {
            gap: 16px;

            .col {
                gap: 24px;
            }
        }

        .report_panel {
            width: calc(100% - 32px);
            margin-bottom: 32px;
            margin-top: 24px;
            // gap: 24px;
        }
    }

    #report_mobile_header {
        margin-top: 0 !important;

        #report_mob_head_left {
            gap: 24px;
        }
    }

    .highcharts_cont {
        max-width: calc(((100vw - 16px - 32px) / 2) - 32px);
    }
}

// BLUE - 100% - 32px
@media only screen and (max-width: 599px) {
    #main_report{
        #copy {
            margin-bottom: 105px;
        }
    }
    #columns {
        gap: 16px;

        .col {
            gap: 24px;
        }
    }

    #section_report {
        .report_panel {
            width: calc(100% - 32px);
            margin-bottom: 32px;
            margin-top: 24px;
            // gap: 24px;
        }
    }

    #report_mobile_header {
        #report_mob_head_left {
            gap: 8px;
        }
    }

    .highcharts_cont {
        max-width: calc((100vw - 32px) - 32px);
    }
}