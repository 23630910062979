@use '../colours';

#main_about {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    section {
        display: flex;
        flex-flow: column nowrap;
    }

    .bio>img {
        width: 166px;
        margin-bottom: 12px;
    }

    #about_arc {

        h1 {
            font-family: 'Quattrocento', Georgia, serif;
            font-style: normal;
            font-weight: 700;
            margin: 0;
        }

        p {
            font-style: normal;
            font-weight: 600;
        }
    }

    #bios {
        display: flex;

        .bio {
            display: flex;
            align-items: center;

            .bio_box {
                background-color: #E3EAE7;
                border-radius: 28px;
                padding: 24px;

                h2 {
                    font-size: 24px;
                    line-height: 32px;
                    margin: 0;
                    font-style: normal;
                    font-weight: 400;
                }

                .details {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.25px;
                    margin: 16px 0 26px 0;
                    color: #3F4946;
                }

                .title {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 0.1px;
                    color: colours.$primary
                }
            }
        }
    }
}


// RED
@media only screen and (min-width: 1240px) {

    #main_about {
        section {
            width: 1128px;
        }

        #about_arc {
            margin-top: 80px;
            padding-top: 128px;

            h1 {
                font-size: 57px;
                line-height: 64px;
                letter-spacing: -0.25px;
                margin-bottom: 56px;
            }

            p {
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.5px;
            }
        }

        #bios {
            margin-bottom: 168px;
            margin-top: 64px;
            flex-flow: row nowrap;
            gap: 48px;

            .bio {
                gap: 16px;
                width: 50%;
            }
        }
    }

}

// YELLOW
@media only screen and (min-width: 905px) and (max-width: 1239px) {
    #main_about {
        section {
            width: calc(100% - 64px);
        }

        #about_arc {
            margin-top: 80px;
            padding-top: 96px;

            h1 {
                font-size: 45px;
                line-height: 52px;
                margin-bottom: 56px;
            }

            p {
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.5px;
            }
        }

        #bios {
            margin-bottom: 136px;
            margin-top: 64px;
            flex-flow: column nowrap;
            gap: 48px;

            .bio {
                width: 100%;
                gap: 16px;

                .bio_box {
                    width: 100%;
                }
            }
        }
    }

}

// GREEN
@media only screen and (min-width: 600px) and (max-width: 904px) {
    #main_about {
        section {
            width: calc(100% - 32px);
        }

        #about_arc {
            margin-top: 80px;
            padding-top: 64px;

            h1 {
                font-size: 36px;
                line-height: 44px;
                margin-bottom: 56px;
            }

            p {
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.25px;
            }
        }

        #bios {
            margin-bottom: 164px;
            margin-top: 64px;
            flex-flow: column nowrap;
            gap: 48px;

            .bio {
                gap: 16px;
            }
        }
    }
}

// BLUE
@media only screen and (max-width: 599px) {
    #main_about {
        section {
            width: calc(100% - 32px);
        }

        #about_arc {
            margin-top: 88px;
            padding-top: 48px;

            h1 {
                font-size: 36px;
                line-height: 44px;
                margin-bottom: 40px;
            }

            p {
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.25px;
            }
        }

        #bios {
            margin-bottom: 164px;
            margin-top: 64px;
            flex-flow: column nowrap;
            gap: 32px;

            .bio {
                flex-flow: column nowrap;
                gap: 0;
                align-items: center;
                img{
                    margin: 0;
                }
            }
        }
    }
}