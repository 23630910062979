@use '../colours';

#main_help {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    section {
        display: flex;
        flex-flow: column nowrap;
    }

    h1 {
        font-family: 'Quattrocento', Georgia, serif;
        font-style: normal;
        font-weight: 700;
        text-align: left;
        margin: 0;
    }

    h2 {
        font-style: normal;
        font-weight: 600;
        text-align: left;
        margin-bottom: 32px;
    }

    .faq_list {
        display: flex;
        flex-flow: column nowrap;
        gap: 32px;
    }
}


// RED
@media only screen and (min-width: 1240px) {

    #main_help {

        #sep {
            margin-bottom: 168px;
        }

        h1 {
            margin-top: 80px;
        }

        section,
        h1,
        h2 {
            width: 1128px;
        }

        h1 {
            padding-top: 128px;
            font-size: 57px;
            line-height: 64px;
            letter-spacing: -0.25px;
        }

        h2 {
            font-size: 32px;
            line-height: 40px;
        }

        h2:not(#top_header) {
            margin-top: 96px;
        }

        #top_header {
            margin-top: 56px;
        }

    }

}

// YELLOW
@media only screen and (min-width: 905px) and (max-width: 1239px) {
    #main_help {

        #sep {
            margin-bottom: 136px;
        }

        h1 {
            margin-top: 80px;
        }

        section,
        h1,
        h2 {
            width: calc(100% - 64px);
        }

        h1 {
            font-size: 45px;
            line-height: 52px;
            padding-top: 96px;
        }

        h2 {
            font-size: 28px;
            line-height: 36px;
        }

        h2:not(#top_header) {
            margin-top: 96px;
        }

        #top_header {
            margin-top: 56px;
        }
    }

}

// GREEN
@media only screen and (min-width: 600px) and (max-width: 904px) {
    #main_help {

        #sep {
            margin-bottom: 164px;
        }

        h1 {
            margin-top: 80px;
        }

        section,
        h1,
        h2 {
            width: calc(100% - 32px);
        }

        h1 {
            font-size: 36px;
            line-height: 44px;
            padding-top: 64px;
        }

        h2 {
            font-size: 24px;
            line-height: 32px;
        }

        h2:not(#top_header) {
            margin-top: 96px;
        }

        #top_header {
            margin-top: 56px;
        }
    }
}

// BLUE
@media only screen and (max-width: 599px) {
    #main_help {

        #sep {
            margin-bottom: 164px;
        }

        h1 {
            margin-top: 88px;
        }

        section,
        h1,
        h2 {
            width: calc(100% - 32px);
        }

        h1 {
            font-size: 36px;
            line-height: 44px;
            padding-top: 48px;
        }

        h2 {
            font-size: 24px;
            line-height: 32px;
        }

        h2:not(#top_header) {
            margin-top: 65px;
        }

        #top_header {
            margin-top: 40px;
        }

    }
}