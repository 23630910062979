@use '../colours';

#main_footer {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-end;
    background-color: #001E30;
    width: 100%;
    padding: 64px 0;
    z-index: 1;

    #footer_content {
        display: flex;
        flex-flow: column nowrap;

        #footer_top {
            box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
            background-color: colours.$quaterniary;
            border-radius: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .plate_input_cont {
                align-self: center;
            }

            #text {
                display: flex;
                flex-flow: column nowrap;
                gap: 16px;

                h3 {
                    margin: 0;
                    color: white;
                    font-family: 'Quattrocento', Georgia, serif;
                    font-style: normal;
                    font-weight: 700;
                }

                p {
                    color: white;
                    font-style: normal;
                    font-weight: 600;
                }
            }
        }

        .no_input{
            padding-top: 64px;
        }
        
        #footer_bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;

            p,
            a {
                color: colours.$quaterniary;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: 0.1px;
                text-decoration: none;
            }

            #docs {
                display: flex;
                flex-flow: row nowrap;
                gap: 16px;
            }

            #socials {
                display: flex;
                flex-flow: row nowrap;
                gap: 24px;
            }
        }
    }
}


// RED
@media only screen and (min-width: 1240px) {

    #main_footer {
        max-height: (270px - 64px);

        #footer_content {
            width: 1128px;

            #footer_top {
                padding: 48px 64px;
                width: 1000px;
                margin-bottom: 64px;

                #text {
                    h3 {
                        font-size: 32px;
                        line-height: 40px;
                    }

                    p {
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: 0.5px;
                    }
                }
            }

            
        }
    }

}

// YELLOW
@media only screen and (min-width: 905px) and (max-width: 1239px) {
    #main_footer {
        max-height: (270px - 64px);

        #footer_content {
            width: calc(100% - 32px);

            #footer_top {
                padding: 48px 64px;
                width: calc(100% - 130px);
                margin-bottom: 64px;

                #text {
                    h3 {
                        font-size: 28px;
                        line-height: 36px;
                    }

                    p {
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: 0.5px;
                    }
                }

            }
        }
    }
}


// GREEN
@media only screen and (min-width: 600px) and (max-width: 904px) {
    #main_footer {
        max-height: calc(396px - 64px);

        #footer_content {
            width: calc(100% - 32px);

            #footer_top {
                flex-flow: column nowrap;
                padding: 48px 64px;
                width: calc(100% - 140px);
                gap: 32px;
                margin-bottom: 64px;
                align-self: center;

                #text {
                    h3 {
                        font-size: 28px;
                        line-height: 36px;
                        text-align: center;
                    }

                    p {
                        text-align: center;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: 0.5px;
                    }
                }
            }

            #footer_bottom {
                flex-flow: column nowrap;
                gap: 8px;

                #socials {
                    order: -2;
                    margin-bottom: 8px;
                }

                #docs {
                    order: -1
                }
            }
        }
    }
}

// BLUE
@media only screen and (max-width: 599px) {
    #main_footer {
        max-height: calc(391px - 64px);

        #footer_content {
            width: calc(100% - 32px);

            #footer_top {
                flex-flow: column nowrap;
                padding: 32px 24px;
                width: calc(100% - 50px);
                gap: 32px;
                margin-bottom: 64px;
                align-self: center;
                max-height: 200px;

                .plate_input_cont{
                    max-width: 75vw !important;
                }

                #text {
                    h3 {
                        font-size: 24px;
                        line-height: 32px;
                        text-align: center;
                    }

                    p {
                        font-size: 14px;
                        line-height: 20px;
                        letter-spacing: 0.25px;
                        text-align: center;

                    }
                }
            }

            #footer_bottom {
                flex-flow: column nowrap;
                gap: 8px;
                p{
                    text-align: center;
                }
                #socials {
                    order: -2;
                    margin-bottom: 8px;
                }

                #docs {
                    order: -1;
                    text-align: center;
                    justify-content: space-evenly !important;
                    align-items: center;
                    p{
                        // width: 23vw !important;
                    }
                }
            }
        }
    }
}