@use '../../colours';

.blog_cta {
    background-color: colours.$quaterniary;
    border-radius: 16px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-bottom: 64px;
    text-align: center;

    .sub {
        margin-bottom: 16px;
        color: white;
        font-weight: 600;
    }

    .headline {
        font-family: 'Quattrocento', Georgia, serif;
        color: white;
        font-weight: 700;
    }

    .plate_input_cont {
        align-self: center;
    }
}

// RED - 1128px
@media only screen and (min-width: 905px) {
    .blog_cta {
        padding: 48px 32px;

        .headline {
            font-size: 32px;
        }

        .sub {
            margin-bottom: 16px;
            font-size: 16px;
            letter-spacing: 0.5px;
        }
    }
}

// GREEN - 100% - 32px
@media only screen and (min-width: 600px) and (max-width: 905px) {
    .blog_cta {
        padding: 32px 16px;

        .headline {
            font-size: 24px;
            font-weight: 700;
        }

        .sub {
            margin-bottom: 16px;
            font-size: 14px;
            letter-spacing: 0.5px;
        }
    }
}

// BLUE - 100% - 32px
@media only screen and (max-width: 599px) {
    .blog_cta {
        padding: 32px 16px;

        .headline {
            font-size: 24px;
            font-weight: 700;
        }

        .sub {
            margin-bottom: 16px;
            font-size: 14px;
            letter-spacing: 0.5px;
        }
        .plate_input_cont{
            max-width: 75vw !important;
        }
    }


}