@use '../colours';

#report_nav_main {
    flex-flow: row nowrap;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 998;
    height: 100%;

    #report_nav :first-child.nav_item {
        margin-top: 44px;
    }

    #report_nav {
        background-color: colours.$neutral_dark;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        width: 80px;
        gap: 16px;
        overflow-x: scroll;
        z-index: 999;
        align-items: center;

        .nav_item:has(.alert) {
            .icon {
                padding: 4px 4px;

                img {
                    margin-left: 16px !important;
                }
            }
        }

        .nav_item {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            gap: 4px;
            min-width: 68px;
            max-width: 68px;
            cursor: pointer;

            p {
                transition: .2s ease-in-out;
                font-size: 12px;
                font-style: normal;
                font-weight: 300;
                line-height: 16px;
                letter-spacing: 0.5px;
            }

            .icon {
                transition: .2s ease-in-out;
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                align-items: center;
                padding: 4px 20px;
                border-radius: 100px;

                .alert {
                    border-radius: 100px;
                    background: colours.$fail;
                    align-self: flex-start;
                    width: 18px !important;
                    height: 18px !important;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                    transform: translate(-6px, -6px);

                    p {
                        color: white;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 16px;
                        letter-spacing: 0.5px;
                    }
                }
            }
        }

        .nav_item.active {
            .icon {
                background-color: #B1CCC6;
            }

            p {
                font-weight: 700;
            }
        }
    }

    #components {
        flex-flow: column nowrap;
        background-color: #EFF5F2;
        justify-content: flex-start;
        padding: 12px;
        width: 222px;
        gap: 16px;
        z-index: 999;
        align-items: flex-start;
        border-radius: 0px 16px 16px 0px;
        overflow-y: scroll;

        .premium{
            cursor: pointer !important;
        }

        .list_item {
            padding: 16px 17px;
            display: flex;
            flex-flow: row nowrap;
            gap: 8px;
            align-items: center;

            p {
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0.1px;
                text-align: left;
                color: #3F4946;
            }

            img{
                margin-right: 8px;
            }

            .alert {
                border-radius: 100px;
                background: colours.$fail;
                align-self: flex-start;
                width: 16px !important;
                height: 16px !important;
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                align-items: center;
                align-self: center;
                // transform: translate(0px, -8px);

                p {
                    color: white;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 16px;
                    letter-spacing: 0.5px;
                }
            }

            .hide{
                display: none;
            }
        }
    }
}

@media only screen and (min-width: 1240px) {
    #report_nav_main {
        display: flex;

        #components {
            display: flex;
        }
    }
}

@media only screen and (min-width: 905px) and (max-width:1239px) {
    #report_nav_main {
        display: flex;

        #components {
            display: none;
        }
    }
}

@media only screen and (max-width: 904px) {

    #report_nav_main {
        display: none;
    }
}