@use '../../colours';

@media only screen and (max-width: 950px) {
    #main_404 {
        section {
            flex-flow: column nowrap !important;
            width: auto !important;
            gap: 2em;
            justify-content: flex-start !important;
            margin-top: calc(45px + 1em) !important; // header height + header padding x2 + header border + a gap to the header
            padding: 0.5em !important;

            h1 {
                font-size: calc(12px + 1vw) !important;
            }

            h2 {
                font-size: calc(24px + 4vw) !important;
            }

            .p_content {
                font-size: calc(12px + 1.5vw) !important;
            }

            #content {
                padding: 1em !important;
                width: auto !important;
            }

            #main_img {
                padding: 1em !important;
            }
        }
    }
}

#main_404 {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    height: inherit;

    section {
        margin: 1.5em 0;
        display: flex;
        flex-flow: row nowrap;
        padding: 1em;
        width: calc(25% + 700px);
        margin-top: calc(45px); // header height + header padding x2 + header border + a gap to the header
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;

        #main_img {
            max-width: 85%;
        }


        #content {
            display: flex;
            flex-flow: column nowrap;
            align-items: baseline;
            gap: 0.5em;
            width: 47%;

            h1 {
                color: colours.$primary;
                font-weight: 600;
                font-size: calc(12px + 0.25vw);
                margin: 0;
            }

            h1.red {
                color: #B42318;
            }

            h2 {
                letter-spacing: -1.2px;
                font-weight: 600;
                font-size: calc(18px + 2.2vw);
                margin: 0;
            }

            .p_content {
                color: colours.$grey_text_dark;
                font-size: calc(12px + 0.4vw);
                margin: 1em 0;
            }

            .plate_input_component {
                margin-top: 1em;
                justify-content: flex-start;
            }

            button:not(.plate_input_component>button) {
                cursor: pointer;
                font-size: calc(12px + 0.2vw);
                padding: 0.8em 1.2em;
                border-radius: 8px;
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                font-weight: 600;
                transition: .2s;
                font-family: 'Nunito Sans', sans-serif;
                border: none;
                color: white;
                background-color: colours.$primary;
            }

            button:hover {
                transform: scale(1.1);
            }
        }

    }
}