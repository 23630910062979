.stacked_column{
    position: relative;

    .block {
        width: 100%;
        height: 100%;
        position: absolute;
        left: -5px;
        top: -15px;
        backdrop-filter: blur(7px);
        pointer-events: none;
        z-index: 10;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        pointer-events: all !important;
    }
}
