$primary: #006B5F;
$secondary: #F4FBF8;
$tertiary: #9EF2E3;
$quaterniary: #456179;

$neutral: #E9EFEC;
$neutral_dark: #DAE5E1;

$off_black: #171D1B;
$plate: #FEC84B;

$sep: #BEC9C5;

// Greys
$grey_bg_light: #FCFCFD;
$grey_bg_dark: #F2F4F7;
$grey_bg_med: #F9FAFB;

$grey_text: #475467;
$grey_text_light: #c0c0c0;
$grey_text_dark: #5a6370;

$grey_border_light: #e5e7ec;
$grey_border_dark: #D0D5DD;

// Statuses
$warn: #c05a1f;
$warn_bg_light: #fffdf9;
$warn_border_light: #FEC84B;

$fail_bg: #FFEDEA;
$fail: #BA1A1A;