@use '../../colours';

@media only screen and (max-width: 950px) {
    #main_503 {
        section {
            flex-flow: column nowrap !important;
            width: auto !important;
            align-items: flex-start !important;
            justify-content: flex-start !important;
            gap: 2em;

            div {
                padding: 1em !important;
                order: 1;
                width: auto !important;

            }

            img {
                // padding: 1em !important;
                max-width: 80% !important;

            }
        }
    }
}

#main_503 {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    height: inherit;

    section {
        margin: 1.5em 0;
        display: flex;
        flex-flow: row nowrap;
        padding: 1em;
        width: calc(25% + 700px);
        margin-top: calc(45px + 2em); // header height + header padding x2 + header border + a gap to the header
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;

        img {
            max-width: 60%;
        }


        div {
            display: flex;
            flex-flow: column nowrap;
            align-items: baseline;
            gap: 0.5em;
            width: 40%;

            h1 {
                color: colours.$primary;
                font-weight: 600;
                font-size: calc(12px + 0.25vw);
                margin: 0;
            }

            h2 {
                letter-spacing: -1.2px;
                font-weight: 600;
                font-size: calc(18px + 2.2vw);
                margin: 0;
            }

            p {
                color: colours.$grey_text_dark;
                font-size: calc(12px + 0.4vw);
                margin: 1em 0;
            }

            button {
                cursor: pointer;
                font-size: calc(12px + 0.2vw);
                padding: 0.8em 1.2em;
                border-radius: 8px;
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                font-weight: 600;
                transition: .2s;
                font-family: 'Nunito Sans', sans-serif;
                border: none;
                color: white;
                background-color: colours.$primary;
            }

            button:hover {
                transform: scale(1.1);
            }
        }

    }
}