@use '../../colours';

#overview_panel {
    .check_table {
        display: flex;
        flex-flow: column nowrap;
        gap: 16px;
        padding: 8px 0;

        .full_row :first-child:not(.full_marker) {
            display: flex;
            flex-flow: row nowrap;
            gap: 4px;
        }

        .title_cont {
            display: block !important;
        }

        .full_row {
            transition: .1s ease-in-out;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 16px;
            border-bottom: 1px solid #CAC4D0;
            padding-left: 6px;

            cursor: pointer;

            .full_title,
            .full_marker>p {
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.25px;
                
            }

            .full_title {
                font-weight: 700;
                display: block !important;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                
            }

            .row_key{
                cursor: default;
                padding: 4px 4px 4px 0;
            }

            .marker_container {
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                position: relative;

                .marker_img {
                    width: 18px;
                    height: 18px;
                }

                .full_marker {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    padding: 8px 16px;
                    border-radius: 100px;

                    p {
                        font-weight: 500;
                        transform: translateY(1px);
                    }

                }

                .full_marker_pass {
                    background-color: colours.$secondary;

                    p {
                        color: colours.$primary;
                    }
                }

                .full_marker_fail {
                    background-color: colours.$fail_bg;

                    p {
                        color: colours.$fail;
                    }
                }

                .full_marker_nodata,
                .full_marker_warn {
                    background-color: #FFF2E7;

                    p {
                        color: #E25600;
                    }
                }
            }
        }
    }

    .check_table :last-child:not(.tt_text>*, .tt_text) {
        border-bottom: none;
        padding-bottom: 0;
    }

    h1 {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: colours.$sep;
        text-align: center;
        margin: 0;
        margin-bottom: 12px;
        margin-top: -12px;
    }

    #car_details {
        display: flex;
        flex-flow: column nowrap;
        border: 1px solid colours.$sep;
        border-radius: 12px;
        padding: 16px;
        background-color: white;

        .img_note {
            font-size: 11px;
            font-style: italic;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.5px;
            text-align: center;
        }

        #details_header {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: 8px;
            margin: 8px 0 4px 0;
            justify-content: center;

            img {
                max-width: 40px;
            }

            p {
                font-size: 16px;
                font-style: normal;
                font-weight: 800;
                line-height: 24px;
                letter-spacing: 0.15px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }

        .car_img {
            margin-top: 16px;
            width: 90%;
            align-self: center;
        }

        #details_table {
            display: flex;
            flex-flow: column nowrap;
            margin: 32px 0 8px 0;
            gap: 16px;

            .sep{
                border-bottom: 1px solid colours.$sep;
                width: 100%;
            }
            .details_row {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
                padding: 0 6px;
            }
        }
    }

    #summary_cont {
        display: flex;
        justify-content: space-between;
        flex-flow: column nowrap;

        #completeness {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            position: relative;
            border-top: 1px solid colours.$sep;
            padding-top: 16px;
            padding-left: 8px;
            justify-content: flex-start;
            gap: 16px;
            margin-top: 24px;

            #completeness_text {
                padding: 16px 0;
                display: flex;
                flex-flow: column nowrap;
                gap: 4px;
            }

            #completeness_text :first-child {
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0.5px;
            }

            #completeness_text :last-child {
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0.25px;
                color: #3F4946;
            }
        }
    }

    #vehicle_id {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        gap: 16px;
        padding: 8px 0;

        .blur {
            color: transparent !important;
            text-shadow: 0 0 7px rgba(0, 0, 0, 1) !important;
        }

        .sep {
            border-bottom: 1px solid #CAC4D0;
        }

        .id_row {
            justify-content: space-between;
            display: flex;
            flex-flow: row nowrap;
            gap: 8px;
            padding: 0 6px;
        }
    }

    #ncap {
        display: flex;
        flex-flow: column nowrap;
        gap: 32px;

        #ncap_summary {
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            align-items: flex-start;
            gap: 24px;

            q {
                font-style: normal;
                font-weight: 600;
                color: #3F4946;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.25px;
            }

            #rating_cont {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                #prefix {
                    display: flex;
                    flex-flow: row nowrap;
                    gap: 8px;
                }

                p:not(#rating>p, .tt_text_p) {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 20px;
                    letter-spacing: 0.25px;
                }

                #rating {
                    display: flex;
                    flex-flow: row nowrap;
                    padding: 12px 20px 12px 16px;
                    gap: 8px;
                    background-color: #E3EAE7;
                    border-radius: 16px;
                    height: 20px;
                    justify-content: space-between;
                    align-items: center;
                    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);

                    p {
                        font-size: 14px;
                        line-height: 20px;
                        letter-spacing: 0.25px;
                        font-style: normal;
                        font-weight: 600;
                        color: colours.$primary;
                    }

                    img {
                        max-width: 18px;
                        min-width: 18px;
                    }
                }
            }


        }

        #ncap_details {
            display: flex;
            padding-bottom: 8px;
            gap: 24px;
            flex-flow: column nowrap;

            .detail_item {
                display: flex;
                flex-flow: column nowrap;
                gap: 8px;

                .ncap_text {
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    justify-content: space-between;

                    p {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 0.25px;
                    }
                }

                .ncap_bar {
                    height: 4px;
                    width: 100%;
                    background-color: #DDE4E1;

                    .ncap_bar_inner {
                        height: 4px;
                        background-color: #FFB000;
                    }
                }
            }
        }

        .blur {
            color: transparent !important;
            text-shadow: 0 0 7px rgba(0, 0, 0, 1) !important;
        }

        .blur_green {
            color: transparent !important;
            text-shadow: 0 0 7px colours.$primary !important;
        }

        span {
            text-shadow: none !important;
            color: colours.$off_black !important;
        }
    }

    #timeline_frame {
        .sep {
            width: 100%;
            border-bottom: 1px solid colours.$sep;
            margin: 16px 0;
        }

        .timeline_mobile_row {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: 12px;
            // padding: 8px 0;
            justify-content: space-between;


            .blur {
                color: transparent;
                text-shadow: 0 0 6px rgba(0, 0, 0, 1);
            }

            .timeline_row_left {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                gap: 12px;
            }
        }
    }
}


// GREEN - 100% - 32px
@media only screen and (min-width: 600px) {
    #overview_panel {
        .check_table .title_cont {
            max-width: 19vw;
        }

        #car_details {
            #details_header {
                p {
                    max-width: min(30vw, 248px);
                }
            }
        }
    }
}

// BLUE - 100% - 32px
@media only screen and (min-width: 321px) and (max-width: 599px) {
    #overview_panel {
        #car_details {
            #details_header {
                p {
                    max-width: 60vw;
                }
            }
        }

        .check_table .title_cont {
            max-width: 36vw;
        }
    }
}

@media only screen and (max-width: 320px) {
    #overview_panel {
        .check_table .title_cont {
            max-width: 28vw;
        }

        #car_details {
            #details_header {
                p {
                    max-width: 60vw;
                }
            }
        }
    }
}