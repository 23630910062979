@use '../../colours';

#valuation_panel {
    .val_container {
        margin-left: 16px;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
        background-color: #E3EAE7;
        border-radius: 16px;
        padding: 12px 20px 12px 16px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 8px;

        p {
            color: colours.$primary;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.1px;
        }

        .blur {
            color: transparent !important;
            text-shadow: 0 0 6px colours.$primary !important;
        }
    }

    #valuation {
        display: flex;
        flex-flow: column nowrap;
        gap: 16px;

        #val_header {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: 8px;
            margin: 4px 0;
            justify-content: center;

            img {
                max-width: 40px;
            }

            p {
                font-size: 16px;
                font-style: normal;
                font-weight: 800;
                line-height: 24px;
                letter-spacing: 0.15px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                max-width: calc(100vw - 64px - 32px - 40px - 8px);
            }
        }

        #val_content {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            gap: 8px;

            p:not(.tt_text_p, .val_container>p) {
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: 0.1px;
                text-align: center;
            }


        }

        .car_img {
            width: 80%;
            align-self: center;
            margin-bottom: 8px;
        }
    }

    #real_world {
        display: flex;
        flex-flow: column nowrap;

        .blur {
            color: transparent !important;
            text-shadow: 0 0 7px #000 !important;
        }

        .val_sep {
            border-bottom: 1px solid colours.$sep;
            width: 100%;
        }

        .val_part_row {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            padding: 16px 4px;

            .row_title {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                gap: 8px;
            }
        }
    }

    #real_world :first-child.val_part_row {
        padding-top: 8px;
    }

    #real_world :last-child.val_part_row {
        padding-bottom: 8px;
    }

    #expenses {
        display: flex;
        flex-flow: column nowrap;

        #expenses_header {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            margin-bottom: 16px;
        }

        .hide {
            display: none !important;
        }

        .sep {
            border-bottom: 1px solid colours.$sep;
            width: 100%;
            margin: 16px 0 8px 0;
        }

        #breakdown_dropdown {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            padding: 8px 4px;
            cursor: pointer;

            img {
                transition: .15s ease-in-out;
            }
        }

        #expenses_table {
            margin: 16px 0;
            padding: 16px;
            display: flex;
            flex-flow: column nowrap;
            background-color: #EFF1EF;
            border-radius: 12px;
            
            .blur {
                color: transparent !important;
                text-shadow: 0 0 7px #000 !important;
            }

            .expenses_row {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
                padding: 8px 6px;

                .expenses_key {
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    gap: 8px;
                }
            }
        }

        #expenses_inputs {
            display: flex;
            flex-flow: row nowrap;
            gap: 16px;
            justify-content: stretch;

            .input_item {
                border-radius: 4px 4px 0px 0px;
                background-color: #EFF1EF;
                padding: 8px 16px;
                border-bottom: 1px solid #3F4946;
                flex: 1 0;
                cursor: text;
                
                p {
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 16px;
                    letter-spacing: 0.4px;
                    color: #3F4946;
                }

                .input_box {
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: flex-end;

                    input {
                        background-color: #EFF1EF;
                        border: none;
                        outline: none;
                    }

                    p,
                    input {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px;
                        letter-spacing: 0.25px;
                        color: colours.$off_black;
                    }

                    #mileage_input {
                        width: 45px;
                    }
                }
            }
        }


    }


}