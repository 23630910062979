@use '../colours';

#payment-form {
    width: -webkit-fill-available;
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 14px;
    padding: 30px;
    display: flex;
    flex-flow: column nowrap;

    * {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    }

    #discount_cont {
        p:not(#discount_msg) {
            font-size: 0.93rem;
            margin-bottom: 3px;
        }

        #discount_msg{
            margin-top: 0.1em;
            color: #df1b41;
            font-size: 0.93rem;
            display: none;
        }

        #discount_msg.show{
            display: block;
        }

        .sep {
            border-bottom: 1px solid colours.$grey_border_light;
            width: 100%;
            margin: 1.5em 0;
        }

        #discount {
            display: flex;
            flex-flow: row nowrap;
            gap: 1em;

            input.invalid{
                border: 2px solid #df1b41;
                color: #df1b41;
            }

            input {
                background: none !important;
                outline: 0 !important;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                border: 1px solid #e6e6e6;
                box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
                border-radius: 5px;
                flex-grow: 1;
                padding-left: 12px;
                padding-top: 2px;
                font-size: 16px;
                color: colours.$off_black;
                text-transform: uppercase;
            }

            input::placeholder {
                font-size: 16px;
                text-transform: none;
            }

            #discount_btn {
                background: colours.$primary;
                font-family: "Nunito Sans", sans-serif;
                color: #ffffff;
                border-radius: 4px;
                border: 0;
                padding: 12px 24px;
                font-size: 16px;
                font-weight: 600;
                cursor: pointer;
                display: block;
                transition: all 0.2s ease;
                box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
            }



            #discount_btn:hover {
                filter: contrast(115%);
            }
        }

    }



    #payment-message {
        color: rgb(105, 115, 134);
        font-size: 16px;
        line-height: 20px;
        padding-top: 12px;

        text-align: center;
    }

    #payment-element {
        margin-bottom: 24px;
    }

    #link-authentication-element {
        margin-bottom: 1.6em;
    }

    #checkboxes {
        margin: 1em 0 1em 0;
        display: flex;
        flex-flow: column nowrap;
        line-height: normal;
        gap: 0.2em;
        font-size: calc(12px + 0.2vw);

        label {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: 0.3em;
            cursor: pointer;

        }


        input {
            -webkit-appearance: none;
            appearance: none;
            background: #fff;
            border: 1px solid colours.$grey_border_dark;
            height: 20px;
            width: 20px;
            display: inline-block;
            position: relative;
            border-radius: 6px;
            cursor: pointer;
            background: colours.$grey_bg_med;
            border-color: colours.$grey_border_dark;
            transform: translateY(-2px);
            margin-right: 0.5em;
            aspect-ratio: 1.1;

            &::before {
                content: '';
                position: absolute;
                margin: auto;
                left: 0;
                right: 0;
                bottom: 0;
                overflow: hidden;
                top: 0;
            }
        }

        input:checked::before {
            border-right: 3px solid #000;
            border-bottom: 3px solid #000;
            height: 50%;
            width: 20%;
            transform: rotate(45deg) translateY(-10%) translateX(-20%);
        }

        input:checked::before,
        input:indeterminate::before,
        input[aria-checked=mixed]::before {
            border-right-color: colours.$primary;
            border-bottom-color: colours.$primary;
        }
    }


    button:not(#discount_btn) {
        background: colours.$primary;
        font-family: "Nunito Sans", sans-serif;
        color: #ffffff;
        border-radius: 4px;
        border: 0;
        padding: 12px 16px;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        display: block;
        transition: all 0.2s ease;
        box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
        width: 100%;
    }



    button:hover {
        filter: contrast(115%);
    }

    button:disabled {
        opacity: 0.5;
        cursor: default;
    }

    // Spinner
    .loading_spinner {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        padding: 10px 0;
    }

    .loading_spinner div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 23px;
        height: 23px;
        border: 3px solid #fff;
        border-radius: 50%;
        animation: spinner_animation 1.6s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }

    .loading_spinner div:nth-child(1) {
        animation-delay: -0.45s;
    }

    .loading_spinner div:nth-child(2) {
        animation-delay: -0.3s;
    }

    .loading_spinner div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes spinner_animation {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}

@media only screen and (max-width: 599px) {
    #payment-form {
        padding: 18px;
    }
}