@use '../../colours';

#main_confirmation{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}
#confirmation_panel {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin-top: calc(40px + 16px + 1px + 23px);

    h1 {
        font-family: 'Quattrocento', 'Georgia', serif;
        font-style: normal;
        font-weight: 700;
        text-align: left;
        width: 100%;
    }

    #report_cards {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
        gap: 24px;

        .info_box {
            display: flex;
            flex-flow: row nowrap;
            background-color: colours.$quaterniary;
            border-radius: 12px;
            align-items: center;
            justify-content: space-between;
            gap: 10px;

            .info_left {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;

                .info_image {
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: scale-down;
                    }
                }

                .info_vehicle {
                    display: flex;
                    flex-flow: column nowrap;
                    justify-content: space-evenly;
                    gap: 5px;

                    p {
                        font-style: normal;
                        color: white;

                    }

                    .make {
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 24px;
                        letter-spacing: 0.15px;
                        text-transform: capitalize;
                    }

                    .year {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 0.25px;
                    }
                }
            }

            .info_right {
                display: flex;
                gap: 16px;
                margin-right: 8px;

                .info_plate {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: 'Nunito Sans', sans-serif;
                    font-style: normal;
                    font-weight: 800;
                    background: #FEC84B;
                    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
                    align-items: center;
                    gap: 4px;
                    border-radius: 4px;
                    width: 110px;
                    text-align: center;
                    text-transform: uppercase;
                }

                .btn_view {
                    font-family: 'Nunito Sans', sans-serif;
                }
            }

        }
    }
}

// RED
@media only screen and (min-width: 1240px) {
    #confirmation_panel {
        width: 1128px;
        margin-bottom: 128px;

        h1 {
            margin: 128px 0 56px 0;
            font-size: 57px;
            line-height: 64px;
            letter-spacing: -0.25px;
        }

        #text>p {
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0.5px;
            margin-bottom: 56px;
        }

        .info_box {
            padding: 18px 8px 18px 16px;

            .info_left {
                .info_image {
                    width: 75px;
                    height: 60px;
                    margin-right: 16px;
                }
            }

            .info_right {
                gap: 16px;
                margin-right: 8px;

                .info_plate {
                    font-size: 22px;
                    padding: 4px 16px;
                    width: 110px;
                }
            }
        }
    }
}

// YELLOW
@media only screen and (min-width: 905px) and (max-width: 1239px) {
    #confirmation_panel {
        width: calc(100% - 64px);
        margin-bottom: 96px;

        h1 {
            margin: 96px 0 56px 0;
            font-size: 45px;
            line-height: 52px;
        }

        #text>p {
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0.5px;
            margin-bottom: 56px;
        }

        .info_box {
            padding: 18px 8px 18px 16px;

            .info_left {
                .info_image {
                    width: 75px;
                    height: 60px;
                    margin-right: 16px;
                }
            }

            .info_right {
                gap: 16px;
                margin-right: 8px;

                .info_plate {
                    font-size: 22px;
                    padding: 4px 16px;
                    width: 110px;
                }
            }
        }
    }
}

@media only screen and (min-width: 1240px) and (max-width: 1439px) {
    #confirmation_panel {
        h1 {
            margin: 70px 0 56px 0 !important;
        }
    }
}

// GREEN
@media only screen and (min-width: 600px) and (max-width: 904px) {
    #confirmation_panel {
        width: calc(100% - 32px);
        margin-bottom: 64px;

        h1 {
            margin: 64px 0 32px 0;
            font-size: 36px;
            line-height: 44px;
        }

        #text>p {
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.25px;
            margin-bottom: 56px;
        }

        .info_box {
            padding: 18px 8px 18px 16px;

            .info_left {
                .info_image {
                    width: 75px;
                    height: 60px;
                    margin-right: 16px;
                }
            }

            .info_right {
                gap: 16px;
                margin-right: 8px;

                .info_plate {
                    font-size: 22px;
                    padding: 4px 16px;
                    width: 110px;
                }
            }
        }
    }
}

// BLUE
@media only screen and (max-width: 599px) {
    #confirmation_panel {
        width: calc(100% - 32px);
        margin-bottom: 48px;

        h1 {
            margin: 48px 0 40px 0;
            font-size: 32px;
            line-height: 44px;
        }

        #text>p {
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.25px;
            margin-bottom: 40px;
        }

        .info_box {
            padding: 18px 8px 18px 10px;

            .info_left {
                .info_image {
                    width: calc(75px - (0.2*75px));
                    height: calc(60px - (0.2*60px));
                    margin-right: 8px;
                }
            }

            .info_right {
                flex-flow: column nowrap;
                align-items: flex-end;

                .info_plate {
                    font-size: 15px;
                    padding: 4px 8px;
                    width: 80px;
                }
            }
        }

    }
}