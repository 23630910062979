@use '../../colours';

#certification_panel {

    .img_expand, .dropdown{
        cursor: pointer;
    } 
    .frame_content:has(.mot_history) {
        padding: 0 !important;
    }

    .report_frame:has(#mot_issues) {
        height: fit-content;
    }


    #mot_checks {
        display: flex;
        flex-flow: column nowrap;
        gap: 16px;

        .item_sep {
            border-bottom: 1px solid colours.$sep;
            width: 100%;
        }

        .check_item {
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            padding: 8px;
            gap: 16px;

            .item_header {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                padding: 0 8px;

                .marker {
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    gap: 8px;
                    padding: 8px 16px;
                    border-radius: 100px;

                    img {
                        max-height: 18px;
                        max-width: 18px;
                        transform: translateY(-1px);
                    }
                }
            }

            .item_details {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                padding: 8px 16px;
                border-radius: 12px;
                align-items: center;
                height: 40px;
                gap: 8px;
                text-align: right;

                .expire {
                    color: #49454F;
                }
            }

            .hide {
                display: none;
            }

            .item_right {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                gap: 12px;
            }

        }

        .check_item.fail {
            .marker {
                background-color: colours.$fail_bg;
            }

            .marker>p,
            .item_val {
                color: colours.$fail;
            }

            .item_details {
                background-color: colours.$fail_bg;
            }
        }

        .check_item.pass {
            .marker {
                background-color: colours.$secondary;
            }

            .marker>p,
            .item_val {
                color: colours.$primary;
            }

            .item_details {
                background-color: colours.$secondary;
            }
        }

        .check_item.warn {
            .marker {
                background-color: #FFF2E7;
            }

            .marker>p,
            .item_val {
                color: #E25600;
            }

            .item_details {
                background-color: #FFF2E7;
            }
        }
    }

    #mot_stats {
        display: flex;
        flex-flow: column nowrap;
        gap: 16px;

        #pass_rate {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;

            #pass_rate_title {
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0.25px;
            }

            #mot_perc {
                display: flex;
                flex-flow: row nowrap;
                padding: 16px 20px 16px 16px;
                align-items: center;
                justify-content: space-between;
                gap: 12px;
                border-radius: 16px;
                box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
                background-color: #E3EAE7;

                p {
                    color: colours.$primary;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 0.25px;
                    transform: translateY(1px);
                }
            }
        }

        #summary_breakdown {
            display: flex;
            flex-flow: column nowrap;
            padding: 8px 0;

            .breakdown_item {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: space-between;
                padding: 0 8px;
            }

            .mot_sep {
                border-bottom: 1px solid colours.$sep;
                width: 100%;
                margin: 16px 0;
            }
        }
    }

    .mot_history {
        transform: translateY(-1px);

        .mot_rows {
            display: flex;
            flex-flow: column nowrap;
            padding: 32px 0;
            gap: 20px;

            .mot_row_sep {
                border-bottom: 1px solid colours.$sep;
                width: calc(100% - 48px);
                align-self: center;
                padding-top: 20px;
                transform: translateX(24px);
            }
        }
    }

    #mot_issues {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;

        h3 {
            color: #6F7976;
            text-align: center;
            margin: 0;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.25px;
            margin-bottom: 16px;
        }

        #top_item {
            border-radius: 16px;
            background-color: #E3EAE7;
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
            height: 56px;

            #top_name {
                display: flex;
                flex-flow: row nowrap;
                gap: 12px;
                align-items: center;
            }

            p {
                color: colours.$primary;
            }
        }

        .sep {
            border-bottom: 1px solid colours.$sep;
            width: 100%;
            margin-top: 12px;
        }

        .issues_table_row {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            gap: 16px;
            padding: 4px 16px;
        }

        #issues_table {
            width: 100%;
            display: flex;
            flex-flow: column nowrap;
            gap: 12px;
            margin-top: 12px;
        }

    }

    #mot_insights {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        gap: 16px;

        #insights_figures {
            display: flex;
            flex-flow: column nowrap;

            .insights_sep {
                border-bottom: 1px solid colours.$sep;
            }

            .insights_item {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
                height: 49px;
                padding: 4px 16px;
            }
        }
    }

    .blur {
        color: transparent !important;
        text-shadow: 0 0 7px rgba(0, 0, 0, 1) !important;
    }

    .blur_advi {
        color: transparent !important;
        text-shadow: 0 0 7px #E25600 !important;
    }

    .blur_fail {
        color: transparent !important;
        text-shadow: 0 0 7px #BA1A1A !important;
    }

    .blur_img {
        filter: blur(7px);
    }
}


// BLUE - 100% - 32px
@media only screen and (max-width: 599px) {
    #certification_panel {
        .frame_content:has(#mot_insights) {
            padding: 16px 8px !important;
        }
        
    }
}