@use '../colours';

#main_homepage section:nth-child(even) {
    background-color: colours.$secondary;
}

@media only screen and (min-width: 1240px) and (max-width: 1439px) {
    #opener {
        padding: 70px 0 !important;
    }
}

#main_homepage {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;

    section {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        width: 100%;
    }

    .section_cont {
        display: flex;
        flex-flow: column nowrap;
    }

    #opener {
        margin-top: calc(40px + 16px + 1px + 24px);

        .section_cont {
            gap: 1em;

            #opener_image {
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                align-items: center;

                img{
                    border-radius: 8px;
                }
            }

            #opener_content {
                display: flex;
                flex-flow: column nowrap;
                gap: 0;

                h1 {
                    font-family: 'Quattrocento', 'Georgia', serif;
                    font-style: normal;
                    font-weight: 700;
                }

                h2 {
                    font-style: normal;
                    font-weight: 600;
                    color: colours.$grey_text;
                }

                .plate_input_cont {
                    margin: 24px 0;
                }

                #opener_list {
                    display: flex;
                    flex-flow: column wrap;

                    .opener_list_item {
                        display: flex;
                        flex-flow: row nowrap;
                        height: 40px;
                        justify-content: center;
                        align-items: center;
                        justify-content: space-between;
                        gap: 16px;
                        align-self: stretch;

                        p {
                            font-style: normal;
                            font-weight: 600;
                        }
                    }

                }
            }
        }
    }

    #features {
        .section_cont {
            display: flex;
            flex-flow: column nowrap;

            #reports {
                display: flex;
                justify-content: space-between;

                #text {

                    h2 {
                        font-family: 'Quattrocento', Georgia, serif;
                        font-style: normal;
                        font-weight: 700;
                        margin: 0;
                    }

                    h3,
                    p {
                        font-style: normal;
                        font-weight: 600;
                        margin: 0;
                    }

                    #preview_header {
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 25px;

                        #nav {
                            display: flex;
                            flex-flow: row nowrap;
                            gap: 32px;

                            img {
                                cursor: pointer;
                                transition: .2s ease-in-out;
                            }

                            img:hover {
                                opacity: 0.6;
                            }
                        }
                    }


                }

                #preview {
                    img {
                        border: 4px solid #101828;
                        border-radius: 8px;
                    }
                }
            }

            #steps {
                border-top: 1px solid colours.$sep;
                display: flex;
                justify-content: space-between;
                padding-top: 32px;
                gap: 10px;

                .step_item {
                    display: flex;
                    gap: 16px;
                    align-items: center;

                    .step_text {
                        display: flex;
                        flex-flow: column nowrap;
                        justify-content: space-between;
                        gap: 4px;
                    }

                    .step_title {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 24px;
                        letter-spacing: 0.15px;
                        margin: 0;
                    }

                    .step_subtitle {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 0.25px;
                    }

                    .marker {
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: center;
                        align-items: center;
                        height: 40px !important;
                        width: 40px !important;
                        aspect-ratio: 1;
                        border-radius: 100%;
                        background-color: colours.$primary;

                        p {
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 24px;
                            letter-spacing: 0.15px;
                            color: colours.$secondary;
                        }
                    }
                }
            }

        }
    }

    #reviews {
        .section_cont {
            display: flex;
            flex-flow: column nowrap;

            h2 {
                font-family: 'Quattrocento', Georgia, serif;
                font-style: normal;
                font-weight: 700;
                margin: 0;
            }

            #review_text {
                font-style: normal;
                font-weight: 600;
            }

            #review_footer {
                display: flex;
                justify-content: space-between;
                align-items: center;

                #info {
                    display: flex;
                    gap: 32px;

                    #review_info {
                        display: flex;
                        flex-flow: column nowrap;
                        justify-content: space-between;

                        p {
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 24px;
                            letter-spacing: 0.15px;
                        }
                    }
                }

                #review_nav {
                    display: flex;
                    flex-flow: row norwap;
                    gap: 24px;

                    * {
                        font-family: 'Nunito Sans', sans-serif;
                    }
                }
            }
        }
    }

    #applications {
        .section_cont {
            display: flex;
            flex-flow: column nowrap;

            h2 {
                font-family: 'Quattrocento', 'Georgia', serif;
                font-style: normal;
                font-weight: 700;
            }

            #items {
                display: flex;
                flex-flow: column nowrap;

                .app_item {
                    display: flex;
                    align-items: center;

                    h3 {
                        font-style: normal;
                        font-weight: 600;
                        margin: 24px 0 16px 0;
                    }

                    p:not(.app_marker>p) {
                        font-style: normal;
                        font-weight: 600;
                    }

                    .app_marker {
                        padding: 10px 24px;
                        border: 1px solid #6F7976;
                        border-radius: 100px;
                        width: min-content;

                        p {
                            color: colours.$primary;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 20px;
                            letter-spacing: 0.1px;
                        }
                    }
                }
            }
        }
    }

    #why_choose_us {

        .section_cont {
            h2 {
                font-family: 'Quattrocento', 'Georgia', serif;
                font-style: normal;
                font-weight: 700;
            }

            #why_content {
                display: flex;

                #list {
                    display: flex;
                    flex-flow: column nowrap;

                    .list_item {
                        display: flex;
                        flex-flow: row nowrap;
                        align-items: center;
                        justify-content: space-between;

                        .marker {
                            display: flex;
                            flex-flow: row nowrap;
                            justify-content: center;
                            align-items: center;
                            height: 40px !important;
                            width: 40px !important;
                            aspect-ratio: 1;
                            border-radius: 100%;
                            background-color: colours.$tertiary;
                            margin-right: 16px;

                            p {
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 24px;
                                letter-spacing: 0.15px;
                            }
                        }

                        p:not(.marker>p) {
                            flex-grow: 1;
                            text-align: left;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 24px;
                            letter-spacing: 0.5px;
                        }
                    }
                }
            }
        }

    }

    #faqs {
        .section_cont {
            display: flex;
            flex-flow: column nowrap;

            h2 {
                font-family: 'Quattrocento', 'Georgia', serif;
                font-style: normal;
                font-weight: 700;
            }

            #faq_intro {
                display: flex;
                align-items: flex-end;

                #faq_text {
                    display: flex;
                    flex-flow: column nowrap;

                    p,
                    ul,
                    li {
                        font-style: normal;
                        font-weight: 600;
                    }
                }
            }

            #questions {
                display: flex;
                flex-flow: column nowrap;
                margin-top: 64px;
            }
        }
    }
}

// RED
@media only screen and (min-width: 1240px) {
    #main_homepage {
        .section_cont {
            width: 1128px;
        }

        #opener {
            padding: 120px 0;

            .section_cont {
                flex-flow: row nowrap;
                justify-content: space-between;

                #opener_image {
                    img {
                        width: 507px;
                    }
                }

                #opener_content {
                    h1 {
                        font-size: 57px;
                        line-height: 64px;
                        margin: 47px 0 0 0;
                        max-width: 550px;
                        letter-spacing: -0.25px;
                    }

                    h2 {
                        margin: 32px 0;
                        line-height: 28px;
                        font-size: 18px;
                    }

                    #opener_list {
                        width: 330px;
                        height: 120px;

                        .opener_list_item {
                            padding: 0px 24px 0px 16px;

                            p {
                                font-size: 16px;
                                line-height: 24px;
                                letter-spacing: 0.5px;
                            }
                        }
                    }
                }

                #opener_image {
                    img {
                        width: 465px;
                    }
                }
            }
        }

        #features {
            padding: 128px 0;

            .section_cont {
                gap: 64px;

                #reports {
                    flex-flow: row nowrap;
                    gap: 92px;

                    #preview {
                        img {
                            width: 516px;
                        }
                    }

                    #text {
                        max-width: 512px;

                        h2 {
                            font-size: 57px;
                            line-height: 64px;
                            letter-spacing: -0.25px;
                            margin-bottom: 48px;
                        }

                        h3 {
                            font-size: 28px;
                            line-height: 36px;
                        }

                        p {
                            font-size: 16px;
                            line-height: 24px;
                            letter-spacing: 0.5px;
                        }
                    }
                }

                #steps {
                    flex-flow: row nowrap;
                }
            }
        }

        #reviews {
            padding: 128px 0;

            .section_cont {
                h2 {
                    font-size: 57px;
                    line-height: 64px;
                    letter-spacing: -0.25px;
                    margin-bottom: 56px;
                }

                #review_text {
                    font-size: 22px;
                    line-height: 28px;
                    margin-bottom: 64px;
                    height: 84px;
                }
            }
        }

        #applications {
            padding: 128px 0;

            .section_cont {

                h2 {
                    font-size: 57px;
                    line-height: 64px;
                    letter-spacing: -0.25px;
                    margin: 0 0 56px 0;
                }

                #items {
                    gap: 96px;

                    .app_item {
                        gap: 64px;

                        h3 {
                            font-size: 32px;
                            line-height: 40px;
                        }

                        p:not(.app_marker>p) {
                            font-size: 16px;
                            line-height: 24px;
                            letter-spacing: 0.5px;

                        }
                    }
                }
            }
        }

        #why_choose_us {
            padding: 128px 0;

            .section_cont {

                h2 {
                    font-size: 57px;
                    line-height: 64px;
                    letter-spacing: -0.25px;
                    margin: 0 0 56px 0;
                }

                #why_content {
                    flex-flow: row nowrap;
                    gap: 96px;

                    #why_text {
                        width: 516px;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 24px;
                        letter-spacing: 0.5px;
                    }

                    #list {
                        width: 516px;

                        .list_item {
                            padding: 4px 24px 4px 16px;
                        }
                    }
                }
            }
        }

        #faqs {
            padding: 128px 0 220px 0;

            .section_cont {

                h2 {
                    font-size: 57px;
                    line-height: 64px;
                    letter-spacing: -0.25px;
                    margin: 0;
                }

                #faq_intro {
                    gap: 64px;

                    #faq_text {
                        gap: 56px;

                        p {
                            font-size: 16px;
                            line-height: 24px;
                            letter-spacing: 0.5px;
                        }
                    }
                }

                #questions {
                    gap: 32px;
                }
            }
        }
    }
}

// YELLOW
@media only screen and (min-width: 905px) and (max-width: 1239px) {
    #main_homepage {
        .section_cont {
            width: calc(100% - 64px);
        }

        #opener {
            padding: 60px 0;

            .section_cont {
                flex-flow: row nowrap;
                justify-content: space-evenly;

                #opener_content {
                    h1 {
                        font-size: 45px;
                        margin: 47px 0 0 0;
                        max-width: 439px;
                        letter-spacing: -0.25px;
                    }

                    h2 {
                        margin: 24px 0;
                        line-height: 24px;
                        font-size: 16px;
                        letter-spacing: 0.15px;
                    }

                    #opener_list {
                        width: 330px;
                        height: 120px;

                        .opener_list_item {
                            padding: 0px 24px 0px 16px;

                            p {
                                font-size: 14px;
                                line-height: 20px;
                                letter-spacing: 0.25px;
                            }
                        }
                    }
                }

                #opener_image {
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    margin-top: 45px;

                    img {
                        width: 353px;
                    }
                }
            }
        }

        #features {
            padding: 96px 0;

            .section_cont {
                gap: 64px;

                #reports {
                    flex-flow: row nowrap;
                    gap: 44px;

                    #preview {
                        img {
                            width: 408px;
                        }
                    }

                    #text {
                        max-width: 512px;

                        h2 {
                            font-size: 45px;
                            line-height: 52px;
                            margin-bottom: 40px;
                        }

                        h3 {
                            font-size: 28px;
                            line-height: 36px;
                        }

                        p {
                            font-size: 16px;
                            line-height: 24px;
                            letter-spacing: 0.5px;
                        }

                    }
                }

                #steps {
                    flex-flow: row nowrap;
                }
            }
        }

        #reviews {
            padding: 96px 0;

            .section_cont {
                h2 {
                    font-size: 45px;
                    line-height: 52px;
                    margin-bottom: 56px;
                }

                #review_text {
                    font-size: 22px;
                    line-height: 28px;
                    height: 84px;
                    margin-bottom: 64px;
                }
            }
        }

        #applications {
            padding: 96px 0;

            .section_cont {

                h2 {
                    font-size: 45px;
                    line-height: 52px;
                    margin: 0 0 56px 0;
                }

                #items {
                    gap: 128px;

                    .app_item {
                        gap: 96px;

                        img {
                            max-width: 45vw;
                        }

                        h3 {
                            font-size: 28px;
                            line-height: 36px;
                        }

                        p:not(.app_marker>p) {
                            font-size: 16px;
                            line-height: 24px;
                            letter-spacing: 0.5px;

                        }
                    }
                }
            }
        }

        #why_choose_us {
            padding: 96px 0;

            .section_cont {

                h2 {
                    font-size: 45px;
                    line-height: 52px;
                    margin: 0 0 56px 0;

                }

                #why_content {
                    flex-flow: row nowrap;
                    justify-content: space-between;
                    gap: 15px;

                    #why_text {
                        width: 50%;
                        max-width: 500px;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 24px;
                        letter-spacing: 0.5px;
                    }

                    #list {
                        width: 50%;
                        max-width: 500px;

                        .list_item {
                            padding: 4px 24px 4px 16px;
                        }
                    }
                }
            }

        }

        #faqs {
            padding: 96px 0 180px 0;

            .section_cont {

                h2 {
                    font-size: 45px;
                    line-height: 52px;
                    margin: 0;
                }

                #faq_intro {
                    gap: 64px;

                    #faq_text {
                        gap: 56px;

                        p {
                            font-size: 16px;
                            line-height: 24px;
                            letter-spacing: 0.5px;
                        }
                    }
                }

                #questions {
                    gap: 32px;
                }
            }
        }
    }
}

// GREEN
@media only screen and (min-width: 600px) and (max-width: 904px) {
    #main_homepage {
        .section_cont {
            width: calc(100% - 32px);
        }

        #opener {
            padding: 64px 0 0 0;

            .section_cont {
                flex-flow: column nowrap;

                #opener_content {
                    align-items: center;

                    h1 {
                        font-size: 45px;
                        text-align: center;
                        max-width: 600px;
                        margin: 0;
                        letter-spacing: -0.25px;

                    }

                    h2 {
                        margin: 24px 0;
                        line-height: 24px;
                        text-align: center;
                        font-size: 16px;
                        letter-spacing: 0.15px;
                    }

                    .plate_input_cont {
                        align-self: center;
                    }

                    #opener_list {
                        height: 80px;
                        width: 500px;

                        .opener_list_item {
                            padding: 0px 24px 0px 16px;

                            p {
                                font-size: 14px;
                                line-height: 20px;
                                letter-spacing: 0.25px;
                            }
                        }
                    }
                }

                #opener_image {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;

                    img {
                        margin-top: 40px;
                        max-height: 50vh;
                        // margin-bottom: 20px;
                    }
                }
            }
        }

        #features {
            padding: 96px 0;

            .section_cont {
                gap: 48px;

                #reports {
                    flex-flow: column nowrap;
                    gap: 44px;

                    #preview {
                        align-self: center;

                        img {
                            width: 100%;
                            max-width: 560px;
                        }
                    }

                    #text {
                        h2 {
                            font-size: 36px;
                            line-height: 44px;
                            margin-bottom: 32px;
                        }

                        h3 {
                            font-size: 24px;
                            line-height: 32px;
                        }

                        p {
                            font-size: 14px;
                            line-height: 20px;
                            letter-spacing: 0.25px;
                        }
                    }
                }

                #steps {
                    flex-flow: row nowrap;
                }
            }
        }

        #reviews {
            padding: 96px 0;

            .section_cont {
                h2 {
                    font-size: 36px;
                    line-height: 44px;
                    margin-bottom: 56px;
                }

                #review_text {
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.15px;
                    margin-bottom: 64px;
                    height: 120px;
                }

                #review_footer {
                    align-self: flex-start;
                    flex-flow: column nowrap;
                    align-items: flex-start;
                    gap: 56px;
                }
            }
        }

        #applications {
            padding: 96px 0;

            .section_cont {

                h2 {
                    font-size: 36px;
                    line-height: 44px;
                    margin: 0 0 56px 0;
                }

                #items {
                    gap: 64px;

                    .middle {
                        .app_text {
                            order: -1;
                        }
                    }

                    .app_item {
                        flex-flow: column nowrap;
                        gap: 16px;

                        .app_text {
                            width: 100%;
                        }

                        h3 {
                            font-size: 24px;
                            line-height: 32px;
                        }

                        p:not(.app_marker>p) {
                            font-size: 14px;
                            line-height: 20px;
                            letter-spacing: 0.25px;

                        }
                    }
                }
            }
        }

        #why_choose_us {
            padding: 96px 0 120px 0;

            .section_cont {

                h2 {
                    font-size: 36px;
                    line-height: 44px;
                    margin: 0 0 56px 0;

                }

                #why_content {
                    flex-flow: column nowrap;
                    align-items: center;
                    gap: 40px;

                    #why_text {
                        width: 100%;

                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 0.5px;
                    }

                    #list {
                        width: 100%;
                        max-width: 520px;

                        .list_item {
                            padding: 4px 24px 4px 16px;
                        }
                    }
                }
            }

        }

        #faqs {
            padding: 96px 0 180px 0;

            .section_cont {

                h2 {
                    font-size: 36px;
                    line-height: 44px;
                    margin: 0;
                }

                #faq_intro {
                    gap: 64px;

                    #faq_text {
                        gap: 56px;

                        p {
                            font-size: 14px;
                            line-height: 20px;
                            letter-spacing: 0.25px;
                        }
                    }
                }

                #questions {
                    gap: 32px;
                }
            }
        }

    }
}

@media only screen and (max-width: 850px) {
    #faq_intro {
        flex-flow: column nowrap;
        gap: 40px !important;

        img {
            max-width: 100%;
            align-self: center;
        }
    }
}

// BLUE
@media only screen and (max-width: 599px) {
    #main_homepage {

        .section_cont {
            width: calc(100% - 32px);
        }

        #opener {
            padding: 32px 0 0 0;

            .section_cont {
                flex-flow: column nowrap;

                #opener_content {
                    align-items: center;
                    max-width: 90vw;
                    align-self: center;

                    h1 {
                        font-size: 36px;
                        text-align: center;
                        max-width: 328px;
                        margin: 0;
                        line-height: 44px;
                        letter-spacing: normal;
                    }

                    h2 {
                        margin: 16px 0;
                        line-height: 24px;
                        font-size: 16px;
                        letter-spacing: 0.15px;
                        text-align: center;
                    }

                    .plate_input_cont {
                        align-self: center;
                    }

                    #opener_list {
                        width: 328px;
                        height: 120px;
                        max-width: 97vw;

                        .opener_list_item {
                            padding: 0px 5px 0px 10px;
                            gap: 4px;

                            p {
                                font-size: 14px;
                                line-height: 20px;
                                letter-spacing: 0.25px;
                            }
                        }
                    }
                }

                #opener_image {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;

                    img {
                        margin-top: 48px;
                        width: 328px;
                        width: 100vw;
                        // max-width: 98vw;
                        // margin-bottom: 20px;
                        border-radius: 0;
                    }
                }
            }
        }

        #features {
            padding: 80px 0;

            .section_cont {
                gap: 40px;

                #reports {
                    flex-flow: column nowrap;
                    gap: 44px;

                    #preview {
                        img {
                            width: 100%;
                        }
                    }

                    #text {
                        h2 {
                            font-size: 36px;
                            line-height: 44px;
                            margin-bottom: 32px;
                        }

                        h3 {
                            font-size: 24px;
                            line-height: 32px;
                        }

                        p {
                            font-size: 14px;
                            line-height: 20px;
                            letter-spacing: 0.25px;
                        }
                    }
                }

                #steps {
                    flex-flow: column nowrap;
                    align-items: flex-start;
                    // margin-left: 16px;
                    gap: 24px;

                    .step_item {
                        width: 260px;
                        padding: 16px;
                    }
                }
            }
        }

        #reviews {
            padding: 80px 0;

            .section_cont {
                h2 {
                    font-size: 36px;
                    line-height: 44px;
                    margin-bottom: 40px;
                }

                #review_text {
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.15px;
                    margin-bottom: 32px;
                    height: 200px;
                }

                #review_footer {
                    flex-flow: column nowrap;
                    align-self: flex-start;
                    gap: 16px;
                    align-items: flex-start;
                }
            }
        }

        #applications {
            padding: 80px 0;

            .section_cont {

                h2 {
                    font-size: 36px;
                    line-height: 44px;
                    margin: 0 0 56px 0;
                }

                #items {
                    gap: 64px;

                    .middle {
                        .app_text {
                            order: -1;
                        }
                    }

                    .app_item {
                        flex-flow: column nowrap;
                        gap: 16px;
                        align-items: center;

                        img {
                            max-width: 90vw;
                        }

                        h3 {
                            font-size: 24px;
                            line-height: 32px;
                        }

                        p:not(.app_marker>p) {
                            font-size: 14px;
                            line-height: 20px;
                            letter-spacing: 0.25px;

                        }
                    }
                }
            }
        }

        #why_choose_us {
            padding: 80px 0 135px 0;

            .section_cont {

                h2 {
                    font-size: 36px;
                    line-height: 44px;
                    margin: 0 0 40px 0;
                }

                #why_content {
                    flex-flow: column nowrap;
                    gap: 40px;

                    #why_text {
                        width: 100%;

                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 24px;
                        letter-spacing: 0.5px;
                    }

                    #list {
                        width: 100%;

                        .list_item {
                            padding: 8px 0 8px 0;
                        }
                    }
                }
            }

        }

        #faqs {
            padding: 80px 0 160px 0;

            .section_cont {

                h2 {
                    font-size: 36px;
                    line-height: 44px;
                    margin: 0;
                }

                #faq_intro {
                    gap: 40px;

                    #faq_text {
                        gap: 40px;

                        p {
                            font-size: 14px;
                            line-height: 20px;
                            letter-spacing: 0.25px;
                        }
                    }
                }

                #questions {
                    gap: 24px;
                }
            }
        }
    }
}